(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var _variables = require("./_variables");

jQuery(function ($) {
  if (_variables.currentPage == "ccrz__ProductDetails") {
    console.log('Product Detail Page');

    CCRZ.offsetCalc = function () {
      var i = 0;

      for (i = 0; i < $('#myTab li').length; i++) {
        var itemOff = $('.tu-' + i).offset();
        var itemOffTop = itemOff.top;
        $('.zi-' + i).data("offset", itemOffTop);
      }
    };

    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode; //Scroll to spec started

    CCRZ.pdpSpecNav = function () {
      var tabPanesLocation = [];
      $('.cc_tab-pane').each(function (index) {
        var tabPane = document.getElementById('tu-' + index);
        var tabPaneLocation = tabPane.getBoundingClientRect();
        tabPanesLocation.push(tabPaneLocation);
      });
      var mqL = window.matchMedia('(min-width: 990px)');
      var headerSpace = null;

      if (mqL.matches) {
        headerSpace = 105;
      } else {
        headerSpace = 56;
      }

      var tabNavigation = $('#myTab');
      var locker = false;
      $(window).scroll(function () {
        var top_of_element = tabNavigation.offset().top;
        var bottom_of_element = tabNavigation.offset().top + tabNavigation.outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();

        if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element && !locker) {
          // the element is visible, do something
          locker = true;
          CCRZ.offsetCalc();
          CCRZ.pagevars.pdpspecnav.update();
        } else {
          // the element is not visible, do something else
          CCRZ.pagevars.pdpspecnav.update();
        }

        if ($('.nav-tabs').is('js-is-sticky, js-is-stuck')) {
          $('.navholder').height($('.nav-tabs').height());
        } else {
          $('.navholder').height(0);
        }
      });
      var couponHeight = 0;

      if ($('.coupon_notification').is(":hidden")) {
        couponHeight = 0;
      } else {
        couponHeight = $('.coupon_notification').height();
      }

      if (isIE11) {
        $('#myTab li>a').on('click', function (e) {
          e.preventDefault();
          CCRZ.pagevars.pdpspecnav.update();
          CCRZ.offsetCalc();
          var indexofEl = $(this).data("index");

          if ($('.cc_nav-tabs').hasClass('js-is-sticky')) {
            document.documentElement.scrollTop = $('.tu-' + indexofEl).offset().top - (200 + couponHeight);
          } else {
            document.documentElement.scrollTop = $('.tu-' + indexofEl).offset().top - (285 + couponHeight);
          }

          if ($(window).width() < 991) {
            var offsetTop = tabPanesLocation[indexofEl].y - (couponHeight + $(".mobile_navbar").height() + $("#myTab").height());
            $('body').scrollTop(offsetTop);
            setTimeout(function () {
              CCRZ.detectScroll();
              CCRZ.highlightActiveTab();
            }, 550);
          }
        });
      } else {
        $('#myTab li>a').on('click', function (e) {
          e.preventDefault();
          $('.cc_tab-pane').removeClass('active');
          CCRZ.pagevars.pdpspecnav.update();
          CCRZ.offsetCalc();
          var indexofEl = $(this).data("index");
          document.documentElement.scrollTop = $('.tu-' + indexofEl).offset().top - (200 + couponHeight);

          if ($(window).width() < 991) {
            var offsetTop = tabPanesLocation[indexofEl].y - (couponHeight + $(".mobile_navbar").height() + $("#myTab").height());
            $('body').scrollTop(offsetTop);
            setTimeout(function () {
              CCRZ.detectScroll();
              CCRZ.highlightActiveTab();
            }, 550);
          }
        });
      }

      CCRZ.detectScroll = function (elementIndex) {
        if ($(window).width() < 768) {
          $('body').on('scroll', function () {
            if (typeof elementIndex != 'undefined') {
              $('#tu-' + elementIndex).addClass('active');
            } else {
              $('.cc_tab-pane').removeClass('active');
            }
          });
        } else if ($(window).width() < 991) {
          $(window).on('scroll', function () {
            if (typeof elementIndex != 'undefined') {
              $('#tu-' + elementIndex).addClass('active');
            } else {
              $('.cc_tab-pane').removeClass('active');
            }
          });
        }
      };

      CCRZ.highlightActiveTab = function () {
        $('.cc_tab-content > .cc_tab-pane').each(function (index) {
          var tab = $(this);

          if ($(window).width() < 768) {
            var topDistance = couponHeight + $(".mobile_navbar").height() + $("#myTab").height() + 50;

            if ($(tab).offset().top <= topDistance) {
              $("#myTab li").removeClass('active');
              $("#myTab li:nth-child(" + (index + 1) + ")").addClass('active');
            }
          } else if ($(window).width() < 991) {
            var topDistance = couponHeight + $(".mobile_navbar").height() + $("#myTab").height() + 50;
            var elementTopDistance = $(tab).offset().top - $(window).scrollTop();

            if (elementTopDistance <= topDistance) {
              $("#myTab li").removeClass('active');
              $("#myTab li:nth-child(" + (index + 1) + ")").addClass('active');
            }
          } else if ($(window).width() >= 991) {
            var topDistance = $(".cc_header").height() + $(".cc_menu_container").height() + $("#myTab").height() + 50;
            var elementTopDistance = $(tab).offset().top - $(window).scrollTop();

            if (elementTopDistance <= topDistance) {
              $("#myTab li").removeClass('active');
              $("#myTab li:nth-child(" + (index + 1) + ")").addClass('active');
            }
          }
        });
      };

      if ($(window).width() < 768) {
        $('body').on('scroll', function () {
          CCRZ.highlightActiveTab();
        });
      } else {
        $(window).on('scroll', function () {
          CCRZ.highlightActiveTab();
        });
      }

      $(window).on('resize', function () {
        CCRZ.offsetCalc();

        if (mqL.matches) {
          headerSpace = 105;
        } else {
          headerSpace = 56;
        }

        CCRZ.pagevars.pdpspecnav.cleanup();
        CCRZ.pagevars.pdpspecnav = stickybits('#myTab', {
          stickyBitStickyOffset: headerSpace + couponHeight,
          useStickyClasses: true
        });
        $('#myTab').width($('.tabs_container').width());

        if (CCRZ.TierInfoView) {
          CCRZ.TierInfoView.render();
        }
      });
      CCRZ.pagevars.pdpspecnav = stickybits('#myTab', {
        stickyBitStickyOffset: headerSpace + couponHeight,
        useStickyClasses: true
      });
      $('#myTab').width($('.tabs_container').width());
      CCRZ.offsetCalc();
    };

    setTimeout(function () {
      CCRZ.pdpSpecNav();
    }, 1500); //Scroll to spec ended
    //Spec nav class toggle started

    $('#myTab li').on('click', function (e) {
      if (!$(this).hasClass("active")) {
        $('#myTab li').removeClass("active");
        $(this).addClass("active");
        setTimeout(function () {
          CCRZ.offsetCalc();
        }, 400);
      }
    }); //Spec nav class toggle ended
  }
});

},{"./_variables":9}],2:[function(require,module,exports){
"use strict";

var _variables = require("./_variables");

if (_variables.currentPage == "ccrz__ProductList") {
  CCRZ.plpScrollTop = 0;
  CCRZ.pubSub.on('view:productListHeader:refresh', function () {
    $('div[data-noresults=false]').css('display', 'block');

    if (CCRZ.collections.ProductList) {
      CCRZ.collections.ProductList.prototype.fetch = function (callback) {
        var coll = this; // format input to pass to the remote action

        var inputData = CCRZ.productListPageModel.formatInput();
        coll.invokeContainerLoadingCtx($(".deskLayout"), "findProducts", JSON.stringify(inputData), function (response) {
          if (response && response.success && response.data && response.data.productList) {
            response.data.productList.forEach(function (product) {
              if (product.osfisexclusive) {
                response.data.productList.splice(response.data.productList.indexOf(product), 1);
              }
            }); // Store page state attributes

            CCRZ.productListPageModel.set(_.pick(response.data, 'prodCurrentIndex', 'prodCount', 'prodHasMore', 'productPricingData')); // Store the entire list of products in the buffer

            CCRZ.productListBuffer.reset(response.data.productList, {
              sort: true
            }); // Pull out the first X products from the buffer

            var itemsToRender = CCRZ.productListBuffer.remove(CCRZ.productListBuffer.slice(0, CCRZ.productListPageModel.get('pageSize')));
            CCRZ.productListPageModel.set('renderedCount', itemsToRender.length); // Update the collection

            coll.reset(itemsToRender);
            CCRZ.productListPageModel.set('bufferHasMore', response.data.prodHasMore || CCRZ.productListBuffer.length > 0);
          } else {
            CCRZ.productListPageModel.set('prodCount', 0);
            CCRZ.productListPageModel.set('noResults', true);
            CCRZ.productListPageModel.set('prodHasMore', false);
            CCRZ.productListPageModel.set('bufferHasMore', CCRZ.productListBuffer.length > 0);
            CCRZ.productListPageModel.set('renderedCount', 0);
            CCRZ.productListPageModel.set('prodCurrentIndex', '');
            coll.reset();
            CCRZ.productListBuffer.reset();
          }

          CCRZ.pubSub.trigger('model:collectionsProductList:fetch', CCRZ.productListPageModel);
          callback();
        }, {
          escape: false,
          buffer: false,
          timeout: 120000
        });
      };
    }

    CCRZ.views.ProductItemsView.prototype.showMore = function () {
      var v = this;
      var renderedCount = this.model.get('renderedCount');
      var currentIndex = renderedCount - 1; // Invoke view more method and render the returned items

      this.collection.findMore(function (items) {
        _.each(items, function (product, index) {
          product.set('index', currentIndex += 1);
          v.addProductView(product);
        });

        v.render();
        var firstTileIdNumber = renderedCount + 1;
        var firstTileId = "#".concat(firstTileIdNumber);
        var headerMenu = $('.menu_container');
        var headerMenuHeight = headerMenu[0].offsetHeight + headerMenu[0].offsetTop + 5;
        setTimeout(function () {
          $('html, body').animate({
            scrollTop: $('body').scrollTop() + ($(firstTileId).offset().top - headerMenuHeight)
          });
        }, 200);
      });
    }; // Clone slected filters CTA to mobile pop-up


    var mobileFilterCloneTimeout;

    if (mobileFilterCloneTimeout) {
      clearTimeout(mobileFilterCloneTimeout);
    }

    mobileFilterCloneTimeout = setTimeout(function () {
      if ($(window).width() < 991) {
        var mobileActiveFilters = $('.widgets-container .cc_filter_breadcrumb_list');
        var selectedFIlterItems = $('.productListHeader .cc_filter_breadcrumb');
        mobileActiveFilters.children().remove();

        if (selectedFIlterItems.length) {
          selectedFIlterItems.clone().appendTo(mobileActiveFilters);
          $('.widgets-container .cc_filter_breadcrumbs').show();
        } else {
          $('.widgets-container .cc_filter_breadcrumbs').hide();
        }
      }
    }, 200);
  });
  CCRZ.pubSub.on('view:productListPageView:init', function (model) {
    var $window = $(window);

    if ($window.width() < 991) {
      model.set('pageSize', 6);
    }
  });
  CCRZ.pubSub.on('view:productItemView:refresh', function (view) {
    view.$el.find('.cc_addto_compare').parent().off().click(function () {
      CCRZ.plpScrollTop = $(window).scrollTop();
    });
    var $window = $(window);

    if ($window.width() < 991) {
      view.gridClass = 'col-md-4 col-xs-6';
      view.renderDesktop();
    }
  });
  CCRZ.pubSub.on('model:collectionsProductList:fetch', function (model) {
    var $window = $(window);

    if ($window.width() < 991) {
      if (model.get('prodCount') > 6) {
        model.set('pageSize', 6); // model.set('renderedCount', 6);
      }
    }
  });
  CCRZ.pubSub.on('view:specGroupListView:refresh', function () {
    var $window = $(window);

    function checkWidth() {
      if ($window.width() >= 992) {
        $('.cc_spec_group_container:first-child .cc_spec_values_container').collapse('show');
      } else if ($window.width() < 991) {
        $('.cc_spec_group_container:first-child .cc_spec_values_container').collapse('hide');
      }
    }

    ;
    checkWidth();
    $window.resize(checkWidth);
  });
}

},{"./_variables":9}],3:[function(require,module,exports){
"use strict";

$(function () {
  if (CCRZ.pagevars.currentPageName === "ccrz__MyAccount") {
    CCRZ.pubSub.on('view:PageMessagesView:refresh', function () {
      if ($('.cc_reset_password_form').is(':visible')) {
        $('#resetForm .form-group').each(function (i, e) {
          var alert = $(e).find('.page_message .alert');

          if (alert.length) {
            $(e).addClass('form-group--has-error');
          } else {
            $(e).removeClass('form-group--has-error');
          }
        });
      }
    });
  }
});

},{}],4:[function(require,module,exports){
"use strict";

var _variables = require("./_variables");

$(document).ready(function () {
  if (_variables.currentPage == "ccrz__CheckoutNew") {
    // The method used to send the selected payment type
    CCRZ.subsc = _.extend(CCRZ.subsc || {});
    CCRZ.subsc.checkoutPaymentMethods = _.extend({
      className: 'osf_ctrl_Checkout',
      setPaymentType: function setPaymentType(paymentType) {
        this.invokeCtx('setPaymentType', paymentType, function (response) {}, {
          buffer: false,
          nmsp: false,
          timeout: 120000
        });
      }
    }, CCRZ.RemoteInvocation); // The checkCartStatus method calls the fetchCart method and checks the satus of the cart. 
    // If the cart is open then it proceeds with the payment. Otherwise it will display an error message that inform the user that the cart is closed,
    // paymentType - is the payment type (eg: 'Cash on Delivery)
    // makePayment -  the method that completes the payment (eg: makeCDPayment)
    // event - the event object

    CCRZ.checkCartStatus = function (paymentType, makePayment, event) {
      CCRZ.models.cartDetails = CCRZ.CloudCrazeModel.extend({
        className: 'cc_ctrl_CheckoutRD',
        fetch: function fetch(cartId, callback) {
          var model = this;
          this.invokeContainerLoadingCtx($('body'), 'fetchCart', function (response) {
            if (response && response.success && response.data) {
              if (response.data.attributeList) {
                model.attributeList = model.parseAttributes(response.data);
              }

              model.set(model.parse(response.data));
            }

            callback();
          }, {
            escape: false
          });
        }
      });
      var cartStatus = new CCRZ.models.cartDetails();

      if (CCRZ.cartCheckoutModel && CCRZ.cartCheckoutModel.attributes.hasOwnProperty('encryptedId') && CCRZ.cartCheckoutModel.attributes.encryptedId) {
        cartStatus.fetch(CCRZ.cartCheckoutModel.attributes.encryptedId, function () {
          if (!cartStatus.attributes.isValidated) {
            $("#overlay").remove();
            $('.paymentBtn').removeClass('disabled');
            $('.messagingSection-Error').show().html('<div class="alert alert-danger">' + CCRZ.pagevars.pageLabels.ERROR_INVALID_CLOSED_CART + '</div>');
            window.scrollTo(0, 0);
          } else {
            CCRZ.subsc.checkoutPaymentMethods.setPaymentType(paymentType);
            makePayment(event);
          }
        });
      }
    };

    if (CCRZ.views.cartCheckoutView) {
      CCRZ.views.cartCheckoutView.prototype.loadDefaultViews = function () {
        if (CCRZ.Checkout.userInfo) {
          CCRZ.Checkout.userInfo.register(this);
        }

        if (CCRZ.Checkout.shipping) {
          CCRZ.Checkout.shipping.register(this);
        }

        if (CCRZ.Checkout.review) {
          CCRZ.Checkout.review.register(this);
        }

        if (CCRZ.Checkout.payment) {
          CCRZ.Checkout.payment.register(this);
        }

        if (CCRZ.Checkout.ccConfirmation) {
          CCRZ.Checkout.ccConfirmation.register(this);
        }
      };

      CCRZ.views.cartCheckoutView.prototype.goToPosition = function (index) {
        this.currStep = index;
        this.subView = this.subViewArray[index].view;
        if (this.subView.initSetup) this.subView.initSetup(_.bind(this.render, this));else {
          this.subView.render();
          this.render();
        }

        if (index >= 4) {
          $(".checkout-nav-steps").hide();
        }
      };
    }

    if (CCRZ.models.PaymentModel) {
      CCRZ.models.PaymentModel.prototype.processPayment = function (paymentData) {
        var paymentDataJson = JSON.stringify(paymentData);
        this.invokeContainerLoadingCtx($('.checkoutContent'), 'placeOrderNew', paymentDataJson, function (response) {
          CCRZ.console.log('recieved response from placeOrderNew');

          if (response && response.success) {
            if ($(".paymentTypeContainer").hasClass("cc") && CCRZ.cartCheckoutView) {
              // setTimeout(function() {
              CCRZ.subsc = _.extend(CCRZ.subsc || {});
              CCRZ.subsc.ccFetchOrders = _.extend({
                className: 'osf_ctrl_Checkout_BIE',
                fetchOrder: function fetchOrder(cartId, callback) {
                  this.invokeCtx('fetchOrder', cartId, function (response) {
                    if (response.success) {
                      callback(response);
                    } else {
                      console.error('response -----> ', response);
                    }
                  }, {
                    buffer: false,
                    //this call will be executed by itself
                    nmsp: false //defines that this is a call to a subscriber class

                  }); //end invokeCtx call
                }
              }, CCRZ.RemoteInvocation);
              CCRZ.models.ccNavModel = CCRZ.CloudCrazeModel.extend();
              CCRZ.models.ccOrderDetail = CCRZ.CloudCrazeModel.extend({
                className: "osf_ctrl_Checkout_BIE",
                fetch: function fetch(callback) {
                  var model = this;
                  CCRZ.subsc.ccFetchOrders.fetchOrder(CCRZ.pagevars.currentCartID, function (response) {
                    if (response.data) {
                      response.data.EOrderItemsS.forEach(function (orderItem) {
                        response.data.mockProduct.forEach(function (mockProduct) {
                          if (orderItem.product === mockProduct.sfid) {
                            orderItem.mockProduct = mockProduct;

                            if (response.data.productURLMap[orderItem.product]) {
                              orderItem.mockProduct.friendlyUrl = response.data.productURLMap[orderItem.product];
                            }
                          }
                        });
                        orderItem.minorLines = [];
                        response.data.EOrderItemsS.forEach(function (minorLine) {
                          if (minorLine.orderLineType === "Minor") {
                            if (minorLine.parentOrderItem === orderItem.sfid) {
                              orderItem.minorLines.push(minorLine);
                            }
                          }
                        });

                        if (!orderItem.minorLines.length) {
                          delete orderItem.minorLines;
                        }
                      });
                      delete response.data.mockProduct;
                      delete response.data.productURLMap;
                      model.parseAttributes(response.data);
                    }

                    model.set(model.parse(response.data));
                    callback();
                  }, {
                    buffer: false,
                    timeout: 120000,
                    escape: false
                  });
                },
                parseAttributes: function parseAttributes(data) {
                  _.each(data.orderItems, function (item) {
                    _.each(item.minorLines, function (line) {
                      item.topAttributes = [];

                      if (line.primaryAttr) {
                        line.primaryAttr.displayName = data.attributeList[line.primaryAttr.sfid].displayName;
                        item.topAttributes.push(_.find(data.attributeList, function (a) {
                          return a.sfid === line.primaryAttr.parentAttribute;
                        }));
                      }

                      if (line.secondaryAttr) {
                        line.secondaryAttr.displayName = data.attributeList[line.secondaryAttr.sfid].displayName;
                        item.topAttributes.push(_.find(data.attributeList, function (a) {
                          return a.sfid === line.secondaryAttr.parentAttribute;
                        }));
                      }

                      if (line.tertiaryAttr) {
                        line.tertiaryAttr.displayName = data.attributeList[line.tertiaryAttr.sfid].displayName;
                        item.topAttributes.push(_.find(data.attributeList, function (a) {
                          return a.sfid === line.tertiaryAttr.parentAttribute;
                        }));
                      }
                    });
                  });
                } // , sendOutboundOrder: function () {
                //     var model = this;
                //     this.invokeContainerLoading($('.deskLayout'), 'sendOutboundOrder', CCRZ.pagevars.storefrontName, CCRZ.pagevars.portalUserId, model.get("sfid"), function (response) {
                //         if (response) CCRZ.pubSub.trigger("model:OrderDetail:orderResponseReceived", response);
                //     }, {
                //         buffer: false
                //     });
                // }

              });
              CCRZ.views.ccOrderNav = CCRZ.CloudCrazeView.extend({
                templatePhone: CCRZ.util.template(CCRZ.uiProperties.OrderNav.phone.tmpl),
                templateDesktop: CCRZ.util.template(CCRZ.uiProperties.OrderNav.desktop.tmpl),
                viewName: "OrderNav",
                render: function render() {
                  if (CCRZ.display.isPhone()) {
                    this.setElement($(CCRZ.uiProperties.OrderNav.phone.selector));
                    this.$el.html(this.templatePhone(this.model.toJSON()));
                  } else {
                    this.setElement($(CCRZ.uiProperties.OrderNav.desktop.selector));
                    this.$el.html(this.templateDesktop(this.model.toJSON()));
                  }

                  CCRZ.pubSub.trigger("view:" + this.viewName + ":refresh", this);
                }
              });
              CCRZ.views.ccOrderDetailView = CCRZ.CloudCrazeView.extend({
                templatePhone: CCRZ.util.template(CCRZ.uiProperties.OrderDetailView.phone.tmpl),
                templateDesktop: CCRZ.util.template(CCRZ.uiProperties.ccOrderDetailView.desktop.tmpl),
                viewName: "ccOrderDetailView",
                init: function init() {
                  var v = this;
                  Handlebars.registerPartial("addressDisplay", $(CCRZ.uiProperties.OrderDetailView.partial.addressDisplay).html());
                  Handlebars.registerPartial("includedDisplay", $(CCRZ.uiProperties.ccOrderDetailView.partial.includedDisplay).html());

                  if (CCRZ.disableAdaptive) {
                    Handlebars.registerPartial("orderAttributesDisplay", $(CCRZ.uiProperties.OrderDetailView.partial.orderAttributesDisplay).html());
                  }

                  Handlebars.registerPartial("totalsSection", $(CCRZ.uiProperties.ccOrderDetailView.partial.totalsSection).html());
                  Handlebars.registerPartial("orderItemsDesktop", $(CCRZ.uiProperties.ccOrderDetailView.partial.orderItemsDesktop).html());
                  this.navModel = new CCRZ.models.ccNavModel({
                    locale: CCRZ.pagevars.userLocale.startsWith("ar") ? CCRZ.pagevars.arLocale : CCRZ.pagevars.enLocale
                  });
                  this.navView = new CCRZ.views.ccOrderNav({
                    model: this.navModel
                  });
                  this.model.fetch(function () {
                    v.render(); // if (true) {
                    //     v.model.sendOutboundOrder();
                    // }
                  });
                },
                events: {
                  "click .orderHistory": "gotoOrderHistory",
                  'click .prodLink': 'gotoProductDetail'
                },
                render: function render() {
                  var v = this;
                  $(".cc_order_details").hide();

                  if (CCRZ.display.isPhone()) {
                    this.setElement($(CCRZ.uiProperties.OrderDetailView.phone.selector));
                    v.$el.html(v.templatePhone(v.model.toJSON()));
                  } else {
                    this.setElement($(CCRZ.uiProperties.ccOrderDetailView.desktop.selector));
                    v.$el.html(v.templateDesktop(v.model.toJSON()));
                  }

                  this.navView.render();
                  $(".temporaryHidden").removeClass("temporaryHidden");
                  CCRZ.pubSub.trigger("view:" + this.viewName + ":refresh", this);
                  this.postRender();
                },
                postRender: function postRender() {
                  var v = this;

                  if (CCRZ.ga && CCRZ.pagevars.currentPageName.match(/OrderConfirmation$/gi)) {
                    CCRZ.ga.sendTransaction(_.extend({
                      'source': 'orderdetail'
                    }, CCRZ.pagevars), v.model);
                  }

                  $(".cc_order_details").show();
                },
                gotoProductDetail: function gotoProductDetail(event) {
                  var objLink = $(event.currentTarget);
                  var id = objLink.data("id");
                  productDetails(id);
                  return false;
                },
                gotoOrderHistory: function gotoOrderHistory() {
                  window.location = CCRZ.pagevars.currSiteURL + CCRZ.buildQueryString("ccrz__MyAccount?viewState=myOrders");
                }
              });
              CCRZ.ccOrderDetailModel = new CCRZ.models.ccOrderDetail({
                isConfirmation: true
              });
              CCRZ.ccOrderDetailView = new CCRZ.views.ccOrderDetailView({
                model: CCRZ.ccOrderDetailModel
              });
              CCRZ.Checkout = _.extend(CCRZ.Checkout || {}, {
                ccConfirmation: {
                  register: function register(registrar) {
                    registrar.registerViewNew(CCRZ.pagevars.pageLabels['CheckOut_ccOrderConfirmation'], CCRZ.ccOrderDetailView, 'images/your_information_header.png', CCRZ.pagevars.userLocale.startsWith("ar") ? CCRZ.pagevars.arLocale : CCRZ.pagevars.enLocale);
                  }
                }
              });
              CCRZ.cartCheckoutView.loadDefaultViews();
              var newurl = window.location.href + '&OrderConfirmation=Success';
              window.history.pushState({
                path: newurl
              }, '', newurl);
              CCRZ.cartCheckoutView.goToPosition(8);
              $(".itemNr").text(0);
            } else {
              if (response.data.placeURL) {
                window.location = response.data.placeURL + getCSRQueryString();
              } else {
                orderDetails(response.data);
              }
            }
          } else if (response && response.messages && _.isArray(response.messages) && response.messages.length > 0) {
            CCRZ.pubSub.trigger('pageMessage', response);

            if (response.data && !response.data.isValidated) {
              setTimeout(function () {
                //wait 3 seconds, then redirect to the cart page
                cartDetails();
              }, 3000);
            }
          } else {
            CCRZ.pubSub.trigger('pageMessage', {
              messages: [{
                type: 'CUSTOM',
                labelId: 'Checkout_OrderPlaceError',
                severity: 'ERROR',
                classToAppend: 'messagingSection-Error'
              }]
            });
          }
        }, {
          escape: false,
          timeout: 120000
        });
      };
    }

    CCRZ.pubSub.on('view:ccOrderDetailView:refresh', function () {
      $(".temporaryHidden").remove();
    });

    if (CCRZ.views.UserInfoView) {
      CCRZ.views.UserInfoView.prototype.renderDesktop = function () {
        var v = this;
        v.$el.html(v.templateDesktop(CCRZ.cartCheckoutModel.toJSON()));
        $('body').animate({
          scrollTop: 0
        }, 0);
      };
    }

    $(".home_slider.cc_home_slider").css("display", "block");

    if (CCRZ.views.ShippingView) {
      CCRZ.views.ShippingView.prototype.renderView = function (currTemplate) {
        var v = this;
        v.$el.html(currTemplate(CCRZ.cartCheckoutModel.toJSON()));
        v.$el.find(".shippingCost").html(formatPrice(CCRZ.cartCheckoutModel.attributes.shippingCharge));
        if (CCRZ.pagevars.pageConfig.isTrue('SO.ShowReqDate')) v.updateRequestedDate();
        v.handleTerms();

        if (CCRZ.ga) {
          CCRZ.ga.sendPageview(_.extend({
            'source': 'Checkout'
          }, CCRZ.pagevars), {
            'title': 'Checkout - Shipping Information',
            'page': [window.location.pathname, CCRZ.ga.safeParams()].join('?')
          });
        }

        $('body').animate({
          scrollTop: 0
        }, 0);
      };

      CCRZ.views.ShippingView.prototype.initSetup = function (callback) {
        var v = this;
        this.requestDateData.fetch(function () {
          v.shippingData.fetch(function () {
            if (CCRZ.pagevars.pageConfig.isTrue('SO.DsplShipOptions')) {
              CCRZ.cartCheckoutModel.set('shippingMethods', v.shippingData.toJSON());

              if (CCRZ.cartCheckoutModel.attributes.shippingMethod && v.shippingData.length > 0 && v.shippingData.get(CCRZ.cartCheckoutModel.attributes.shippingMethod)) {
                //We have a selected method and selectable methods
                //CCRZ-1847 Begin- this is done to fix this issue
                var selMethod = v.shippingData.get(CCRZ.cartCheckoutModel.attributes.shippingMethod);
                CCRZ.cartCheckoutModel.set('shippingCharge', selMethod.attributes.price); //CCRZ-1847 End- this is done to fix this issue
              } else if (v.shippingData.length > 0) {
                //No selected method but selectable methods
                var selShip = v.shippingData.at(0);
                CCRZ.cartCheckoutModel.set('shippingMethod', selShip.attributes.uniqueId);
                CCRZ.cartCheckoutModel.set('shippingCharge', selShip.attributes.price);
              } else {
                //No selectable methods
                CCRZ.cartCheckoutModel.unset('shippingMethod');
                CCRZ.cartCheckoutModel.set('shippingCharge', 0.00);
              }
            } else {
              //No selectable methods
              CCRZ.cartCheckoutModel.unset('shippingMethod');
              CCRZ.cartCheckoutModel.set('shippingCharge', 0.00);
            }

            callback();
          });
        });
      };
    }

    if (CCRZ.views.OrderReviewView) {
      CCRZ.views.OrderReviewView.prototype.renderView = function (currTemplate) {
        var v = this;
        v.$el.html(currTemplate(CCRZ.cartCheckoutModel.toJSON()));

        if (CCRZ.cartCheckoutModel.attributes['messages']) {
          CCRZ.pubSub.trigger('pageMessage', CCRZ.cartCheckoutModel.attributes);
        }

        if (CCRZ.ga) {
          CCRZ.ga.sendPageview(_.extend({
            'source': 'Checkout'
          }, CCRZ.pagevars), {
            'title': 'Checkout - Order Review',
            'page': [window.location.pathname, CCRZ.ga.safeParams()].join('?')
          });
          v.postRender();
        }

        $('body').animate({
          scrollTop: 0
        }, 0);
      };
    }

    if (CCRZ.views.PaymentProcessorView) {
      CCRZ.views.PaymentProcessorView.prototype.oneTimeRender = function () {
        var me = this;
        CCRZ.pubSub.on('action:paymentViewInit', function (payView) {
          me.childPaymentViews.push(payView);
        });
        CCRZ.pubSub.on('action:paymentViewReady', function (payType, callback) {
          callback({
            selector: '.paymentTypeContainer.' + payType
          });

          if (payType === "po") {
            $(".makePOPayment").removeClass("btn-sm").addClass("paymentBtn btn-lg");
            $(".cc_po_action_buttons .cc_action_buttons").prepend('<button type="button" class="btn btn-primary btn-lg processBack cc_process_back paymentBtn">' + CCRZ.pagevars.pageLabels.Back + '</button>');

            if (CCRZ.pagevars.userLocale.startsWith('ar')) {
              $(".makePOPayment").removeClass("pull-right").addClass("pull-left");
              $(".cc_po_action_buttons .processBack").removeClass("pull-left").addClass("pull-right");
            } else {
              $(".makePOPayment").removeClass("pull-left").addClass("pull-right");
              $(".cc_po_action_buttons .processBack").removeClass("pull-right").addClass("pull-left");
            }
          }
        });
        var modelData = this.model.toJSON();

        _.each(modelData.paymentTypes, function (pt, key) {
          me.paymentData = _.extend(me.paymentData, CCRZ.pagevars.remoteContext, {
            pmt_page: pt[me.paymentData.payKey]
          });

          if (CCRZ.pagevars.currSiteURL) {
            var url = CCRZ.pagevars.currSiteURL + "ccrz__CCPaymentInfo";
            $.post(url, me.paymentData).done(function (data) {
              $('.paymentFuncContainer').append(data);
            });
          } else {
            $.post("/apex/ccrz__CCPaymentInfo", me.paymentData).done(function (data) {
              $('.paymentFuncContainer').append(data);
            });
          }
        }, this);
      };

      CCRZ.views.PaymentProcessorView.prototype.doRender = function (theTemplate, theSelector) {
        this.setElement($(theSelector));
        var modelData = this.model.toJSON();
        this.$el.html(theTemplate(modelData));
        this.oneTimeRender();

        _.each(this.childPaymentViews, function (cv) {
          cv.render();
        });

        $('body').animate({
          scrollTop: 0
        }, 0);
      }; // Overwrote the useStoredPayment method to check first the cart validity


      CCRZ.views.PaymentProcessorView.prototype.useStoredPayment = function (event) {
        var v = this;

        CCRZ.useStoredPO = function (event) {
          if (v.model.get("storedSelected")) {
            var storedPayment = v.model.get("storedSelected");

            _.each(v.model.get('storedPayments'), function (sp) {
              if (sp.sfid == storedPayment) {
                storedPayment = sp;
              }
            });

            CCRZ.pubSub.trigger("action:processPayment", {
              "storedPaymentId": storedPayment.sfid,
              "accountNumber": storedPayment.accountNumber,
              "accountType": storedPayment.accountType,
              "paymentType": "Credit via Purchase Order"
            });

            if (CCRZ.ga) {
              CCRZ.ga.handleSubmitOrder({
                'cartId': CCRZ.pagevars.currentCartID
              });
            }
          } else {
            CCRZ.pubSub.trigger("pageMessage", CCRZ.createPageMessage('ERROR', "storedpayment-messagingSection-Error", 'PaymentProcessor_InvalidPaymentId'));
          }
        };

        if (CCRZ.pagevars.currentPageName && CCRZ.pagevars.currentPageName === "ccrz__CheckoutNew") {
          $(".makePOPayment").addClass("disabled");
          $("body").append('<div id="overlay" class="modal-backdrop fade in"></div>');
          CCRZ.checkCartStatus('Credit via Purchase Order', CCRZ.useStoredPO, event);
        } else {
          CCRZ.useStoredPO(event);
        }
      };
    }

    CCRZ.pubSub.on('view:PaymentsPOView:refresh', function (PaymentsPOView) {
      $('#newWalletForm').validate({
        invalidHandler: function invalidHandler(event, validator) {
          if (!CCRZ.disableAdaptive) {
            CCRZ.handleValidationErrors(event, validator, 'error_messages_section', false);
          }
        },
        rules: {
          accountNumber: {
            required: true
          }
        },
        messages: {
          accountNumber: {
            required: CCRZ.pagevars.pageLabels.PMTPO_POReq
          }
        },
        errorElement: "em",
        errorPlacement: function errorPlacement(error, element) {
          // Needed for bootstrap 3 (per validator documentation)
          if (CCRZ.disableAdaptive) {
            error.addClass("help-block");
            error.insertAfter(element);
          }
        },
        highlight: function highlight(element, errorClass, validClass) {
          if (CCRZ.disableAdaptive) {
            $(element).parents(".col-sm-10").addClass("has-error");
          }
        },
        unhighlight: function unhighlight(element, errorClass, validClass) {
          if (CCRZ.disableAdaptive) {
            $(element).parents(".col-sm-10").addClass("has-success").removeClass("has-error");
          }
        }
      });
      $("#accountNumber").prop('required', true); // Overwrote the makePOPayment method to check first the cart validity

      PaymentsPOView.makePOPayment = function (event) {
        var ev = event;

        CCRZ.poPurchase = function (ev) {
          var formName = $(ev.target).data("id"); //'myWallet';

          var formData = form2js(formName, '.', false, function (node) {}, false, true);

          if (PaymentsPOView.validateInfo(formName, formData)) {
            formData.accountType = 'po';
            formData.paymentType = 'Credit via Purchase Order', CCRZ.pubSub.trigger('action:processPayment', formData);

            if (CCRZ.ga) {
              CCRZ.ga.handleSubmitOrder({
                'cartId': CCRZ.pagevars.currentCartID
              });
            }
          }
        };

        if (CCRZ.pagevars.currentPageName && CCRZ.pagevars.currentPageName === "ccrz__CheckoutNew") {
          event.preventDefault();
          $(".makePOPayment").addClass("disabled");
          $("body").append('<div id="overlay" class="modal-backdrop fade in"></div>');
          var validForm = $('#newWalletForm').validate();
          setTimeout(function () {
            if (!validForm.invalid.accountNumber && typeof validForm.invalid.accountNumber != 'undefined') {
              CCRZ.checkCartStatus('Credit via Purchase Order', CCRZ.poPurchase, ev);
            } else {
              $(".makePOPayment").removeClass("disabled");
              $("#overlay").remove();
              $('#newWalletForm').submit();
            }
          }, 150);
        } else {
          CCRZ.poPurchase(ev);
        }
      };

      PaymentsPOView.events["click .makePOPayment"] = 'makePOPayment';
      PaymentsPOView.delegateEvents();
      $(".useStoredPayment").click(function () {
        $(".useStoredPayment").addClass("disabled");
        $("body").append('<div id="overlay" class="modal-backdrop fade in"></div>');
      });

      if (CCRZ.currentUser.hasOwnProperty("Contact") && CCRZ.currentUser.Contact.hasOwnProperty("Account") && CCRZ.currentUser.Contact.Account.hasOwnProperty("Name") && CCRZ.currentUser.Contact.Account.Name.length > 0) {
        if (CCRZ.currentUser.Contact.Account.Name.toLowerCase().indexOf("anonymous") !== -1) {
          $("#saveAsStoredPayment").parents(".form-group").hide();
          $("#displayName").parents(".form-group").hide();
        }
      } // File upload


      CCRZ.proofFileUpload();
      $("#orderProofUpload .cc_modal_body").find('.form-message__item').remove();
      $("#orderProofUpload .cc_modal_body").prepend('<p class="form-message__item form-message__description"> ' + CCRZ.pagevars.pageLabels.ProofUpload_ModalDescription + '</p>' + '<p class="form-message__item form-message__file-limit alert alert-danger">' + CCRZ.pagevars.pageLabels.ProofUpload_FilesLimit + '</p>' + '<p class="form-message__item form-message__size-limit alert alert-danger">' + CCRZ.pagevars.pageLabels.ProofUpload_SizeLimit + '</p>' + '<p class="form-message__item form-message__upload-success alert alert-success">' + CCRZ.pagevars.pageLabels.ProofUpload_UploadSuccess + '</p>' + '<p class="form-message__item form-message__server-error alert alert-danger">' + CCRZ.pagevars.pageLabels.ProofUpload_UploadFailed + '</p>');
    });
  }
});

},{"./_variables":9}],5:[function(require,module,exports){
"use strict";

var _variables = require("./_variables");

if (_variables.currentPage === 'ccrz__CCForgotPassword') {
  $('.cc_main_content_col input.form-control').addClass('emailInput');
  $(".cc_main_content_col form").validate({
    errorElement: "em",
    errorPlacement: function errorPlacement(error, element) {
      // Needed for bootstrap 3 (per validator documentation)
      if (CCRZ.disableAdaptive) {
        error.addClass("help-block");
        error.insertAfter(element);
      }
    },
    highlight: function highlight(element, errorClass, validClass) {
      if (CCRZ.disableAdaptive) {
        $(element).parents(".col-sm-5").addClass("has-error");
      }
    },
    unhighlight: function unhighlight(element, errorClass, validClass) {
      if (CCRZ.disableAdaptive) {
        $(element).parents(".col-sm-5").addClass("has-success").removeClass("has-error");
      }
    }
  });
  jQuery.validator.addMethod("checkEmail", function (value, element) {
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value);
  }, CCRZ.pagevars.pageLabels['FP_EmailInvalid']);
  $(".emailInput").rules("add", {
    required: true,
    checkEmail: true
  });
}

},{"./_variables":9}],6:[function(require,module,exports){
"use strict";

jQuery(function ($) {
  Handlebars.registerHelper('StaticImagePath', function (path) {
    return CCRZ.pagevars.themeBaseURL + path;
  });
  Handlebars.registerHelper('AttachmentImagePath', function (uri) {
    return CCRZ.pagevars.attachmentURL + uri;
  }); // Do not do this!!!
  //CCRZ.pagevars.country = CCRZ.getCookie("apex__cc_anonymous_Country");

  getCSRQueryString = function (_super) {
    return function () {
      var csrQueryString = '';

      if (CCRZ.pagevars.portalUserId != null) {
        csrQueryString = '&portalUser=' + CCRZ.pagevars.portalUserId;
      }

      if (CCRZ.pagevars.storeName != null) {
        csrQueryString += '&store=' + CCRZ.pagevars.storeName;
      }

      if (CCRZ.pagevars.effAccountId) {
        csrQueryString += '&effectiveAccount=' + CCRZ.pagevars.effAccountId;
      }

      if (CCRZ.pagevars.priceGroupId) {
        csrQueryString += '&grid=' + CCRZ.pagevars.priceGroupId;
      }

      if (CCRZ.pagevars.userLocale) {
        csrQueryString += '&cclcl=' + CCRZ.pagevars.userLocale;
      }

      if (CCRZ.pagevars.country && CCRZ.pagevars.country !== "FreeZone") {
        csrQueryString += '&country=' + CCRZ.pagevars.country;
        console.log(csrQueryString);
      }

      return csrQueryString;
    };
  }(getCSRQueryString);

  Handlebars.registerHelper('debug', function callback(optionalValue) {
    console.log('Current Context:', this); // eslint-disable-line no-console
  });
  Handlebars.registerHelper('savingPercent', function (baseprice, sellerPrice) {
    return "".concat(parseFloat(sellerPrice / baseprice * 100 - 100).toFixed(0), "%");
  });
  Handlebars.registerHelper('isArabic', function (options) {
    /*// Check cookie 
    var currentLoc = CCRZ.getCookie("apex__userLocale");
    if(!currentLoc){
        currentLoc = CCRZ.pagevars.userLocale && CCRZ.pagevars.userLocale.startsWith("ar") ? 'ar' : 'en';
    }*/
    var currentLoc = CCRZ.pagevars.userLocale && CCRZ.pagevars.userLocale.startsWith("ar") ? 'ar' : 'en';

    if (currentLoc && currentLoc === 'ar') {
      $("[class^='col-']").css("float", "right");
      return options.fn(this);
    }

    return options.inverse(this);
  });
  Handlebars.registerHelper('isMobile', function (options) {
    if (document.body.clientWidth < 991) {
      return options.fn(this);
    }

    return options.inverse(this);
  });
  Handlebars.registerHelper('isDesktop', function (options) {
    if (document.body.clientWidth >= 991) {
      return options.fn(this);
    }

    return options.inverse(this);
  });
  Handlebars.registerHelper("showPrice", function (showForGuest, showForLoggedIn) {
    if (CCRZ.pagevars.isGuest && showForGuest || !CCRZ.pagevars.isGuest && showForLoggedIn) {
      return true;
    }

    return false;
  });
});
Handlebars.registerHelper('ellipsisMax', function (context, max) {
  if (context.length > max) {
    context = context.substr(0, max - 1) + '...';
    return context;
  } else {
    return context;
  }
});
Handlebars.registerHelper('createMenuLink', function (linktype, menuId) {
  if (linktype == "Category") {
    return CCRZ.goToPLP(menuId);
  } else if (linktype == "Product") {
    return CCRZ.goToPDP(menuId);
  }
});
Handlebars.registerHelper('countriesFlagsImgPath', function (name) {
  return "images/" + name + ".png";
});
Handlebars.registerHelper('openFourthLevelMenuItemLink', function (linkType, path, friendlyUrl) {
  if (friendlyUrl) {
    friendlyUrl = friendlyUrl.slice(1, friendlyUrl.length);
    var url = CCRZ.pagevars.currSiteURL + friendlyUrl + CCRZ.buildQueryString("");
    return url;
  } else {
    if (linkType == "Category") {
      return CCRZ.pagevars.currSiteURL + "ccrz__ProductList?categoryId=" + CCRZ.buildQueryString(path);
    } else if (linkType == "Product") {
      return CCRZ.pagevars.currSiteURL + "ccrz__ProductDetails?sku=" + CCRZ.buildQueryString(path);
    }
  }
});
Handlebars.registerHelper("checkStaticPagesURL", function (url) {
  if (url && url.indexOf('ccrz__CCPage') >= 0) {
    var countryURL = url.split("&")[0] + "_" + CCRZ.pagevars.countryCode;
    return CCRZ.buildQueryString(countryURL);
  } else {
    return url;
  }
});
Handlebars.registerHelper("calculateSavingPercent", function (basePrice, productPrice) {
  return (100 - productPrice / basePrice * 100).toFixed(2) + "%";
});
Handlebars.registerHelper('calculateCartPrice', function (price, taxRate) {
  price = Number(price);
  var amount = price + price * taxRate / 100;
  return new Handlebars.SafeString(CCRZ.formatPrice(Math.round(amount), false));
});
Handlebars.registerHelper('checkCheckout', function () {
  var cartItems = CCRZ.cartDetailModel.attributes.ECartItemsS.models;
  var state = 0;
  var result = '';
  cartItems.forEach(function (item, index) {
    if (item.attributes.osfshowprice == true) {
      state++;
    }
  });

  if (state == 0) {
    //all products in the cart do NOT have price
    result = 'all false';
  } else if (cartItems.length > state) {
    //mixed cart
    result = 'false';
  } else {
    //all products in the cart have price
    result = 'all true';
  }

  return result;
});
Handlebars.registerHelper('toLowerCase', function (str) {
  return str.toLowerCase();
});
Handlebars.registerHelper('countryBasedLabel', function (labelName) {
  if (CCRZ.pagevars && CCRZ.pagevars.country) {
    return labelName + '_' + CCRZ.pagevars.country;
  }
});
Handlebars.registerHelper('checkPayment', function (payment) {
  if (CCRZ.pagevars.currentPageName === 'ccrz__CheckoutNew') {
    if (CCRZ.cartCheckoutView && CCRZ.cartCheckoutView.hasOwnProperty("model") && CCRZ.cartCheckoutView.model.attributes.hasOwnProperty("osfb2bselectedpaymenttype") && CCRZ.cartCheckoutView.model.attributes.osfb2bselectedpaymenttype === payment) {
      return true;
    }

    return false;
  } else if (CCRZ.pagevars.currentPageName === 'ccrz__InvoicePayment' && payment === 'Credit via Purchase Order') {
    return false;
  } else if (CCRZ.pagevars.currentPageName === 'ccrz__InvoicePayment' && payment === 'Cash via Credit/Debit Card') {
    return true;
  }
});
Handlebars.registerHelper('checkStoredPayment', function (paymentTypes, storedPayments) {
  if (CCRZ.pagevars.currentPageName === 'ccrz__CheckoutNew') {
    var samePayment = false;
    var selectedPayment = CCRZ.cartCheckoutModel.attributes.hasOwnProperty("osfb2bselectedpaymenttype") ? CCRZ.cartCheckoutModel.attributes.osfb2bselectedpaymenttype : "";

    if (Object.keys(paymentTypes).length > 0) {
      Object.keys(paymentTypes).forEach(function (item) {
        if (item === 'po' && selectedPayment === "Credit via Purchase Order" || item === 'cc' && selectedPayment === "Cash via Credit/Debit Card") {
          Object.keys(storedPayments).forEach(function (payment) {
            if (storedPayments[payment].accountType == item) {
              samePayment = true;
            }
          });
        }
      });
    }

    if (samePayment) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
});
Handlebars.registerHelper('stripTags', function (html) {
  if (html) {
    var tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText;
  } else {
    return "";
  }
});
Handlebars.registerHelper('isPOEditForm', function (options) {
  if (CCRZ.pagevars.queryParams.hasOwnProperty("mode") && CCRZ.pagevars.queryParams.mode === "edit") {
    return options.fn(this);
  }

  return options.inverse(this);
});
Handlebars.registerHelper('displayExpirationYearList', function () {
  var strDate = new Date();
  var shortYear = strDate.getFullYear();
  var twoDigitYear = parseInt(shortYear.toString().substr(-2));
  var maxYear = twoDigitYear + 12;
  var options = '';

  while (twoDigitYear < maxYear) {
    options += '<option value="' + twoDigitYear + '">' + twoDigitYear + '</option>';
    twoDigitYear++;
  }

  return new Handlebars.SafeString(options);
});
Handlebars.registerHelper('buildMegaMenuImagePath', function (imgName, options) {
  var url = 'images/Menu_Images/' + imgName;
  if (options.hash["locale"]) url = options.hash["locale"] + "/" + url;

  if (options.hash["staticRes"]) {
    var staticResourceName = options.hash["staticRes"];

    if (staticResourceName.indexOf("__") != -1) {
      if (staticResourceName.startsWith("c__")) {
        staticResourceName = staticResourceName.slice(3);
      }
    } else {
      var nspace = CCRZ.pagevars.namespace;

      if (nspace) {
        if (nspace.endsWith(".")) {
          nspace = nspace.slice(0, nspace.length - 1);
        }

        staticResourceName = nspace + "__" + staticResourceName;
      }
    }

    return new Handlebars.SafeString(CCRZ.pagevars.staticResourceMap[staticResourceName] + "/" + url);
  } else {
    return new Handlebars.SafeString(CCRZ.pagevars.themeBaseURL + url);
  }
});
Handlebars.registerHelper('processPageLabelSuffixMap', function (labelName, suffix) {
  var retLabel = CCRZ.processPageLabelMap(labelName);
  suffix = Number(suffix) + 1;

  if (suffix) {
    var adjusted = labelName + suffix;
    retLabel = CCRZ.processPageLabelMap(adjusted);
  }

  return retLabel;
});
Handlebars.registerHelper('processPageLabelReverse', function (labelName, suffix) {
  var retLabel = suffix + ' ' + CCRZ.pagevars.pageLabels[labelName].split(' ')[0];
  return retLabel;
});
Handlebars.registerHelper('processPageLabePlpPaginationArabic', function (labelName, renderedCount, prodCount) {
  var retLabel = CCRZ.pagevars.pageLabels[labelName];
  retLabel = retLabel.replace('{1}', prodCount);
  retLabel = retLabel.replace('{0}', renderedCount);
  return retLabel;
});
Handlebars.registerHelper('isPermanentAccount', function (options) {
  if (CCRZ.currentUser && CCRZ.currentUser.hasOwnProperty("Contact") && CCRZ.currentUser.Contact.hasOwnProperty("Account") && CCRZ.currentUser.Contact.Account.hasOwnProperty("Name") && CCRZ.currentUser.Contact.Account.Name.length > 0) {
    if (CCRZ.currentUser.Contact.Account.Name.toLowerCase().indexOf("anonymous") == -1) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  }
});
Handlebars.registerHelper('removeSpaces', function (text) {
  return text.replace(/[^A-Z0-9]+/ig, "").toLowerCase();
});
Handlebars.registerHelper('checkCCPayment', function (text, index) {
  var paymentCC = "";

  if (CCRZ.pagevars.country === "KSA" || CCRZ.pagevars.countryCode === "SA") {
    paymentCC = '<label for="method' + index + '" class="payment_name">' + CCRZ.pagevars.pageLabels.cashviacreditdebitcard_KSA + '</label>';
    paymentCC += '<div class="credit_card_logos">';
    paymentCC += '<img src="' + CCRZ.pagevars.themeBaseURL + 'images/mada.svg" class="credit_card_logo" />';
    paymentCC += '<img src="' + CCRZ.pagevars.themeBaseURL + 'images/mastercard.svg" class="credit_card_logo" />';
    paymentCC += '<img src="' + CCRZ.pagevars.themeBaseURL + 'images/visa.svg" class="credit_card_logo" />';
    paymentCC += '</div>';
  } else {
    paymentCC = '<label for="method' + index + '" class="payment_name">' + CCRZ.pagevars.pageLabels.cashviacreditdebitcard + '</label>';
    paymentCC += '<div class="credit_card_logos">';
    paymentCC += '<img src="' + CCRZ.pagevars.themeBaseURL + 'images/mastercard.svg" class="credit_card_logo" />';
    paymentCC += '<img src="' + CCRZ.pagevars.themeBaseURL + 'images/visa.svg" class="credit_card_logo" />';
    paymentCC += '</div>';
  }

  return new Handlebars.SafeString(paymentCC);
});
Handlebars.registerHelper('StoredCreditCardImagePath', function (cardType) {
  var creditCardImgPath = CCRZ.pagevars.themeBaseURL;

  if (cardType === "MASTERCARD") {
    creditCardImgPath += "images/mastercard.svg";
  } else if (cardType === "VISA") {
    creditCardImgPath += "images/visa.svg";
  } else if (cardType === "MADA") {
    creditCardImgPath += "images/mada.svg";
  } else {
    creditCardImgPath += "images/ccdefaultImage.jpg";
  }

  return creditCardImgPath;
});
Handlebars.registerHelper('checkCouponEndDate', function (endDate, options) {
  var currentDate = new Date().getTime();

  if (currentDate > endDate) {
    return options.inverse(this);
  } else {
    return options.fn(this);
  }
});
Handlebars.registerHelper('sumUp', function () {
  var sum = 0,
      v;

  if (arguments) {
    var argsLen = arguments.length;

    for (var i = 0; i < argsLen; i++) {
      v = parseFloat(arguments[i]);
      if (!isNaN(v)) sum += v;
    }
  }

  return new Handlebars.SafeString(sum);
});
Handlebars.registerHelper('isHomepage', function (options) {
  if (CCRZ.pagevars.currentPageName === "ccrz__HomePage") {
    return options.fn(this);
  }

  return options.inverse(this);
});
Handlebars.registerHelper('checkGroupTierPricing', function (tieredPricing, index) {
  if (index === 0 && tieredPricing) {
    return true;
  } else {
    if (CCRZ.cartDetailModel.attributes.ECartItemsS.models[index - 1].attributes.osfgroupedTieredPricingR && CCRZ.cartDetailModel.attributes.ECartItemsS.models[index - 1].attributes.osfgroupedTieredPricingR.sfdcName === tieredPricing) {
      return false;
    } else {
      return true;
    }
  }
});
Handlebars.registerHelper('checkOtherProductGroup', function (index) {
  if (index === 0) {
    return false;
  } else {
    if (CCRZ.cartDetailModel.attributes.ECartItemsS.models[index - 1].attributes.osfgroupedTieredPricingR) {
      return true;
    } else {
      return false;
    }
  }
});
Handlebars.registerHelper('checkPricingData', function (productId, priceType) {
  var priceAmount = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";

  if (CCRZ.productCompareView && CCRZ.productCompareView.model && CCRZ.productCompareView.model.attributes && CCRZ.productCompareView.model.attributes.productPricingData && CCRZ.productCompareView.model.attributes.productPricingData[productId][priceType]) {
    if (priceAmount === "") {
      return CCRZ.productCompareView.model.attributes.productPricingData[productId][priceType];
    } else {
      return CCRZ.productCompareView.model.attributes.productPricingData[productId][priceType][priceAmount];
    }
  }
});
Handlebars.registerHelper('getSKU', function () {
  return CCRZ.pagevars.queryParams.sku;
});
Handlebars.registerHelper('termsURL', function () {
  if (CCRZ.pagevars.currSiteURL) {
    var termsURL = CCRZ.pagevars.currSiteURL + "ccrz__CCPage?pageKey=TermsConditions_" + CCRZ.buildQueryString(CCRZ.pagevars.countryCode);
    return new Handlebars.SafeString(termsURL);
  }
});
Handlebars.registerHelper('console', function (context) {
  return console.log(context);
});
Handlebars.registerHelper('customPrice', function (price, currency) {
  var priceAbs = Handlebars.helpers.priceAbs(price, currency);

  if (price > 0) {
    return priceAbs;
  } else {
    return '- ' + priceAbs;
  }
});
Handlebars.registerHelper('cardLast4Digits', function (context) {
  if (context) {
    return context.slice(context.length - 4);
  } else {
    return '';
  }
});
Handlebars.registerHelper('calculateSubtotalAmount', function (subtotal, discount) {
  if (discount) {
    return subtotal - discount;
  } else {
    return subtotal;
  }
});
Handlebars.registerHelper('calculateCartItemTotal', function (unitPrice, qty) {
  return unitPrice * qty;
});
Handlebars.registerHelper('toFixed', function (amount, nrOfDigits) {
  return amount.toFixed(nrOfDigits);
});
Handlebars.registerHelper('calculateTax', function (amount, tax) {
  return amount + amount * tax / 100;
});
Handlebars.registerHelper('calculatePercentDiscountUnitPrice', function (amount, discount) {
  return amount - amount * discount / 100;
});
Handlebars.registerHelper('concat', function (str1, str2) {
  return str1.toString() + str2.toString();
});
Handlebars.registerHelper('displaycountryLocale', function (locale, options) {
  if (locale === 'ar' || locale === 'en_GB') {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
Handlebars.registerHelper('startsWith', function (str1, str2, options) {
  str2 = str2.split("_")[0];

  if (str1.startsWith(str2)) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
Handlebars.registerHelper('ifCurrSiteURL', function (options) {
  if (CCRZ.pagevars.currSiteURL) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
Handlebars.registerHelper('checkAllowedCompanyTypes', function (companyType) {
  if (companyType !== 'University' && companyType !== 'SPA' && companyType !== 'Inventory Hub' && companyType !== 'Inventory Location') {
    return true;
  }

  return false;
});
Handlebars.registerHelper("showCartItemPrice", function (showPrice, showForGuest, showForLoggedIn) {
  if (CCRZ.cartDetailModel && CCRZ.cartDetailModel.attributes.hasOwnProperty('osfclassification') && (CCRZ.cartDetailModel.attributes.osfclassification === 'RFQ' || CCRZ.cartDetailModel.attributes.osfclassification === 'Spare Parts')) {
    if (showPrice) {
      return true;
    } else if (CCRZ.pagevars.isGuest && showForGuest || !CCRZ.pagevars.isGuest && showForLoggedIn) {
      return true;
    }
  } else if ((CCRZ.pagevars.isGuest && showForGuest || !CCRZ.pagevars.isGuest && showForLoggedIn) && showPrice) {
    return true;
  }

  return false;
});
Handlebars.registerHelper('isGuest', function (options) {
  if (CCRZ.pagevars.isGuest) {
    return options.fn(this);
  }

  return options.inverse(this);
});
Handlebars.registerHelper('checkGuestPromotions', function (coupon, options) {
  if (CCRZ.pagevars.isGuest && coupon) {
    return options.inverse(this);
  }

  return options.fn(this);
});
Handlebars.registerHelper('isCheckout', function (options) {
  if (CCRZ.pagevars.currentPageName === "ccrz__CheckoutNew") {
    return options.fn(this);
  }

  return options.inverse(this);
});
Handlebars.registerHelper('displayBuyerDetail', function (detail) {
  if (CCRZ.cartDetailModel && CCRZ.cartDetailModel.attributes.hasOwnProperty(detail)) return new Handlebars.SafeString(CCRZ.cartDetailModel.attributes[detail]);
});
Handlebars.registerHelper('calculateYouSave', function (netSaveAmount, taxRate) {
  return netSaveAmount + netSaveAmount * taxRate / 100;
});
Handlebars.registerHelper('isKSA', function (options) {
  if (CCRZ.pagevars.country === "KSA") {
    return options.fn(this);
  }

  return options.inverse(this);
});
Handlebars.registerHelper('displayDateFromMiliseconds', function (miliseconds) {
  var date = new Date();
  date.setTime(miliseconds);
  return new Handlebars.SafeString(date.toLocaleDateString("en-GB"));
});
Handlebars.registerHelper('displayDateTimeFromMiliseconds', function (miliseconds) {
  var date = new Date();
  date.setTime(miliseconds);
  return new Handlebars.SafeString(date.toLocaleDateString("en-GB") + ' ' + date.toLocaleTimeString("en-US", {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }));
});
Handlebars.registerHelper('replaceArabicDate', function (date) {
  // ٠ ١ ٢ ٣ ٤ ٥ ٦ ٧ ٨ ٩
  if (date) {
    date = date.replaceAll('٠', '0').replaceAll('١', '1').replaceAll('٢', '2').replaceAll('٣', '3').replaceAll('٤', '4').replaceAll('٥', '5').replaceAll('٦', '6').replaceAll('٧', '7').replaceAll('٨', '8').replaceAll('٩', '9').replaceAll(/\s/g, '').replaceAll(/\u200f/g, '');
    var dateinfo = date.split('/');

    if (dateinfo[0].length < 2) {
      dateinfo[0] = '0' + dateinfo[0];
    }

    if (dateinfo[1].length < 2) {
      dateinfo[1] = '0' + dateinfo[1];
    }

    if (CCRZ.pagevars.userLocale.startsWith('ar')) {
      date = dateinfo.join('/');
    } else {
      date = dateinfo[1] + '/' + dateinfo[0] + '/' + dateinfo[2];
    }

    return new Handlebars.SafeString(date);
  }
});
Handlebars.registerHelper("price", function (amount, currency, options) {
  if (!_.isNull(amount) && !_.isUndefined(amount)) {
    if (_.isUndefined(options)) {
      return new Handlebars.SafeString(formatPrices(amount, false));
    } else {
      return new Handlebars.SafeString(formatPrices(amount, currency));
    }
  } else {
    var undefinedMsg = CCRZ.pagevars.pageLabels["price_undefined"];

    if (_.isNull(undefinedMsg) || _.isUndefined(undefinedMsg)) {
      undefinedMsg = "&nbsp;";
    }

    return new Handlebars.SafeString(undefinedMsg);
  }
});

function formatPrices(amount, theCurrency) {
  if ("TRUE" === CCRZ.pagevars.pageConfig["pgl.curr"]) {
    var theCurrency = theCurrency || CCRZ.userIsoCode;

    if (CCRZ.pagevars.userLocale && CCRZ.pagevars.userLocale.startsWith("ar")) {
      if (amount.toFixed(2) < 0) {
        return CCRZ.priceWithComma(Math.abs(amount).toFixed(2)) + '-  <span>' + CCRZ.pagevars.pageLabels["priceSymbol_" + theCurrency] + "</span>  ";
      } else {
        return CCRZ.priceWithComma(amount.toFixed(2)) + '  <span>' + CCRZ.pagevars.pageLabels["priceSymbol_" + theCurrency] + "</span>  ";
      }
    } else {
      return CCRZ.util.format(CCRZ.pagevars.pageLabels["price_" + theCurrency] || CCRZ.pagevars.pageLabels["price_Undefined"] || "$#,###.00", amount);
    }
  } else {
    var div = $("<div>" + amount + "</div>");
    div.currency({
      region: CCRZ.userIsoCode,
      decimals: CCRZ.pagevars.currencyData.decimalPoints,
      decimal: CCRZ.pagevars.currencyData.charDecimalPoint,
      thousands: CCRZ.pagevars.currencyData.charThousandsPoint,
      currencySymbol: CCRZ.pagevars.currencyData.currencySymbol,
      hidePrefix: !CCRZ.pagevars.currencyData.prefix,
      hidePostfix: CCRZ.pagevars.currencyData.prefix
    });
    return div.html();
  }
}

Handlebars.registerHelper('retURL', function (options) {
  return CCRZ.pagevars.currSiteURL.replace('https://', '') + 'ccrz__CCSiteLogin?cartId=' + CCRZ.currentCart.attributes.encryptedId + '&cclcl=' + CCRZ.pagevars.userLocale + '&country=' + CCRZ.pagevars.country + '&reloaded=true';
});

},{}],7:[function(require,module,exports){
"use strict";

var _variables = require("./_variables");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

console.log('Main js --- current page : ' + _variables.currentPage); // Added Ararbic language support for Bootstrap date picker

$.fn.datepicker.dates['ar'] = {
  days: ['الأحد', 'السبت', 'الجمعه', 'الخميس', 'الأربعاء', 'الثلاثاء', 'الأثنين'],
  daysShort: ['الأحد', 'السبت', 'الجمعه', 'الخميس', 'الأربعاء', 'الثلاثاء', 'الأثنين'],
  daysMin: ['الأحد', 'السبت', 'الجمعه', 'الخميس', 'الأربعاء', 'الثلاثاء', 'الأثنين'],
  months: ['يناير', 'فبراير', 'مارس', 'أبريل/إبريل', 'أيار', 'حزيران', 'تموز', 'آب', 'أيلول', 'تشرين الأول', 'تشرين الثاني', 'كانون الأول'],
  monthsShort: ['يناير', 'فبراير', 'مارس', 'أبريل/إبريل', 'أيار', 'حزيران', 'تموز', 'آب', 'أيلول', 'تشرين الأول', 'تشرين الثاني', 'كانون الأول'],
  today: 'اليوم',
  clear: 'اختيار واضح',
  close: 'إلغاء',
  format: "yyyy/mm/dd",
  titleFormat: "yyyy MM",

  /* Leverages same syntax as 'format' */
  weekStart: 0
};
CCRZ.isArabicPage = false;

CCRZ.arabicLayout = function () {
  /*// Check cookie 
  var currentLoc = CCRZ.getCookie("apex__userLocale");
    // Check url params if current locale is not prsesent in cookies
  if(!currentLoc){
      currentLoc = CCRZ.pagevars.queryParams.cclcl ? CCRZ.pagevars.queryParams.cclcl : CCRZ.pagevars.userLocale;
      currentLoc = currentLoc.split('_')[0];
  }*/
  var currentLoc = CCRZ.pagevars.userLocale && CCRZ.pagevars.userLocale.startsWith("ar") ? 'ar' : 'en';

  if (currentLoc && currentLoc === 'ar') {
    CCRZ.isArabicPage = true;
    $(".deskLayout").attr({
      "dir": "rtl",
      "lang": currentLoc
    });
    $("[class^='col-']").css("float", "right");
  }
};

CCRZ.removePointerEventsOnCollapse = function () {
  $('.collapse').each(function () {
    var $div = $(this);
    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.attributeName === "class") {
          var attributeValue = $(mutation.target).prop(mutation.attributeName);

          if (attributeValue.indexOf('collapsing') > -1) {
            $(".down-arrow, .cc_spec_header, .panel, .fa-caret-down").addClass('no_pointer_events');
          } else {
            setTimeout(function () {
              $(".down-arrow, .cc_spec_header, .panel, .fa-caret-down").removeClass('no_pointer_events');
            }, 100);
          }
        }
      });
    });
    observer.observe($div[0], {
      attributes: true
    });
  });
};

CCRZ.processImageURL = function (obj, styleClass, options) {
  var lobj = obj || {};
  var imgSrc = "";

  if (lobj.sourceType === "Attachment") {
    imgSrc = CCRZ.pagevars.attachmentURL + lobj.uri;
  } else if (lobj.productMediaSource === "Attachment") {
    if (lobj.attachmentsS && lobj.attachmentsS.length) {
      imgSrc = CCRZ.pagevars.attachmentURL + lobj.attachmentsS[0].sfid;
    }
  } else if (lobj.imageFullPath) {
    imgSrc = lobj.imageFullPath;
  } else if (lobj.uri) {
    imgSrc = lobj.uri;
  } else if (lobj.fullImageURL) {
    imgSrc = lobj.fullImageURL;
  } else if (lobj.URI) {
    imgSrc = lobj.URI;
  }

  return imgSrc;
};

Handlebars.registerHelper("productLink", function (product, styleClass, options) {
  var SKU = "";

  if (!_.isUndefined(product)) {
    if (product.linkURL) {
      SKU = product.linkURL;
    } else if (product.sku) {
      SKU = product.sku;
    } else if (product.SKU) {
      SKU = product.SKU;
    } else if (product.productSKU) {
      SKU = product.productSKU;
    }

    var linkObj = '';

    if (product.name) {
      linkObj = {
        name: product.name,
        SKU: SKU,
        friendlyUrl: product.friendlyUrl,
        openInNewWindow: product.openInNewWindow
      };
    } else if (product.sfdcName) {
      linkObj = {
        name: product.sfdcName,
        SKU: SKU,
        friendlyUrl: product.friendlyUrl,
        openInNewWindow: product.openInNewWindow
      };
    }

    var productJSON = _.escape(JSON.stringify(linkObj));
  }

  var content = "";
  var promo = "";

  if (product && product.name) {
    content = _.escape(product.name);
  } else if (product && product.sfdcName) {
    content = _.escape(product.sfdcName);
  }

  if (options && options.hash["image"]) content = options.hash["image"];

  if (options && options.hash["text"]) {
    content = _.escape(_.unescape(options.hash["text"]));
  }

  if (options && options.hash["promo"]) promo = options.hash["promo"];
  var href = CCRZ.goToPDP(null, product);

  if (product.openInNewWindow || product.isNewWindow) {
    return new Handlebars.SafeString("<a href='" + href + '\' target="_blank" onClick="return CCRZ.openPDP(this)" class="' + styleClass + " gp_prod" + "\" data-product= '" + productJSON + "' data-id= '" + SKU + "' data-promo='" + promo + "'>" + content + "</a>");
  } else {
    return new Handlebars.SafeString("<a href='" + href + '\' onClick="return CCRZ.openPDP(this)" class="' + styleClass + " gp_prod" + "\" data-product= '" + productJSON + "' data-id= '" + SKU + "' data-promo='" + promo + "'>" + content + "</a>");
  }
});

CCRZ.createSlider = function () {
  setTimeout(function () {
    if ($(window).width() < 768) {
      // Add slider for featured product images on mobile screen
      var sliderWrap = $('.cc_featured_prod_list');

      if (sliderWrap.find('.cc_featured_col').length > 1) {
        sliderWrap.addClass('osf-imageslider-wrap');
        sliderWrap.find('.cc_featured_col').addClass('osf-imageslider-featureditem');
        sliderWrap.slick({
          dots: false,
          infinite: false,
          speed: 300,
          slidesToShow: 1,
          rtl: CCRZ.isArabicPage,
          adaptiveHeight: true,
          prevArrow: '<button type="button" class="osf-slider-prev"></button>',
          nextArrow: '<button type="button" class="osf-slider-next"></button>'
        });
      }
    }
  }, 500);
};

$(document).ready(function () {
  CCRZ.staticPageLayoutExtend();

  if (CCRZ.pagevars.currentPageName === "ccrz__CCForgotPassword") {
    // Add loading screen
    $(".cc_forgot_password_panel").css('visibility', 'visible');
    var redirect = CCRZ.pagevars.currSiteURL + "ccrz__CCSiteLogin" + CCRZ.buildQueryString("");
    $(".cc_forgot_password_panel .panel-body .form-group:last-child() a").attr('href', redirect);
    $('.form-group a').addClass('textbutton-blue');
    $('.cc_main_content_col form .cc_submit').click(function () {
      CCRZ.setCookieWithPath("apex__country", CCRZ.pagevars.country, null, '/');
      CCRZ.setCookieWithPath("apex__locale", CCRZ.pagevars.userLocale, null, '/');
    });
  }
  /*var currentLoc = CCRZ.getCookie("apex__userLocale");
  if(!currentLoc){
      currentLoc =  CCRZ.pagevars.userLocale && CCRZ.pagevars.userLocale.startsWith("ar") ? 'ar' : 'en'; 
  }*/


  var currentLoc = CCRZ.pagevars.userLocale && CCRZ.pagevars.userLocale.startsWith("ar") ? 'ar' : 'en';

  if (currentLoc && currentLoc === 'ar') {
    $(document.body).addClass('page-layout--arabic');
    $('.cc_main_container').addClass('cc_main_container--ar');
    $('.cc_header').parent('header').addClass('page-header--ar');
  }

  if (_variables.currentPage === "ccrz__Cart") {
    if (CCRZ.views.CartDetailView) {
      CCRZ.views.CartDetailView.prototype.renderDesktop = function () {
        var v = this;
        this.setElement($(CCRZ.uiProperties.CartDetailView.desktop.selector));
        $(CCRZ.uiProperties.CartDetailView.phone.selector).html('');
        v.$el.html(v.templateDesktop(v.model.toJSON())); // Show tiered pricing 

        var nrOfTierItems = $('.shopping-cart-item-container .tier-toggle-col .tier-toggle').length;

        if ($('.shopping-cart-item-container .tier-toggle-col .tier-toggle')[0]) {
          CCRZ.showPricingTiers($(".tier_container_outer")[0], $('.shopping-cart-item-container .tier-toggle-col .tier-toggle')[0], 0, nrOfTierItems);
        } // clone shopping-cart-actions for mobile


        var $shoppingCartAction = $('.shopping-cart-action');
        $('.cc_checkout').append($shoppingCartAction.clone().addClass('visible-sm hidden-md'));
        $shoppingCartAction.addClass('visible-lg visible-md hidden-sm');
      };
    }

    if ($(".cartContainer").html() === "") {
      var model = CCRZ.cartDetailModel;
      model.invokeContainerLoadingCtx($('.deskLayout'), 'fetchCart', function (response, event) {
        model.set('messages', response && response.messages ? response.messages : []);

        if (response && response.data && response.success && event.status) {
          response.data['hasCoupon'] = response.data.couponList && response.data.couponList.length > 0;
          response.data['couponName'] = response.data['hasCoupon'] ? response.data.couponList[0]['couponName'] : null;
          var cartModelData = response.data;
          model.processData(cartModelData, model);
          model.trigger('refreshedFromServer');
          console.log(response.data);
          CCRZ.cartDetailView.render();
        } else {
          console.error("error");
        }

        CCRZ.pubSub.trigger("pageMessage", response);
      }, {
        escape: false,
        timeout: 120000
      });
    }
  }

  if (_variables.currentPage === "ccrz__ProductList" || _variables.currentPage === "ccrz__HomePage") {
    if (CCRZ.views.ReOrderView) {
      CCRZ.views.ReOrderView.prototype.render = function () {
        this.$el.html(this.template(this.collection.toJSON()));

        if ($(window).width() > 991) {
          $(".reorderContainer .cc_filter_icon").click(function () {
            if ($(this).find('.fa-caret-down').hasClass("rotateX0")) {
              $(this).find('.fa-caret-down').removeClass("rotateX0").addClass("rotateX180");
            } else {
              $(this).find('.fa-caret-down').removeClass("rotateX180").addClass("rotateX0");
            }
          });
        }

        return this;
      };
    }
  }

  if (CCRZ.pagevars.currentPageName === "ccrz__InvoicePayment") {
    if (CCRZ.views.PaymentProcessorView) {
      CCRZ.views.PaymentProcessorView.prototype.oneTimeRender = function () {
        var me = this;
        CCRZ.pubSub.on('action:paymentViewInit', function (payView) {
          me.childPaymentViews.push(payView);
        });
        CCRZ.pubSub.on('action:paymentViewReady', function (payType, callback) {
          callback({
            selector: '.paymentTypeContainer.' + payType
          });
        });
        var modelData = this.model.toJSON();

        _.each(modelData.paymentTypes, function (pt, key) {
          me.paymentData = _.extend(me.paymentData, CCRZ.pagevars.remoteContext, {
            pmt_page: pt[me.paymentData.payKey]
          });

          if (CCRZ.pagevars.currSiteURL) {
            var url = CCRZ.pagevars.currSiteURL + "ccrz__CCPaymentInfo";
            $.post(url, me.paymentData).done(function (data) {
              $('.paymentFuncContainer').append(data);
            });
          } else {
            $.post("/apex/ccrz__CCPaymentInfo", me.paymentData).done(function (data) {
              $('.paymentFuncContainer').append(data);
            });
          }
        }, this);
      };

      CCRZ.views.PaymentProcessorView.prototype.doRender = function (theTemplate, theSelector) {
        this.setElement($(theSelector));
        var modelData = this.model.toJSON();
        this.$el.html(theTemplate(modelData));
        this.oneTimeRender();

        _.each(this.childPaymentViews, function (cv) {
          cv.render();
        });

        $('body').animate({
          scrollTop: 0
        }, 0);
      };
    }
  }

  CCRZ.pubSub.on('view:PaymentView:refresh', function () {
    $(".cc_stored_payments_selector.payment-type label").click(function () {
      var $label = $(this);
      $(".cvv_input").val('').parents('.card-cvv-wrap').addClass('hidden');
      $('.cc_use_stored_payment').addClass('disabled no_pointer_events');
      $(".cc_stored_payments_selector.payment-type").removeClass("cc_stored_payments_selector_selected");
      $(this).parent().addClass("cc_stored_payments_selector_selected");
      $(this).parent().find('.card-cvv-wrap').removeClass('hidden').find('.cvv_input').focus(); // Activate ".cc_use_stored_payment" on CVV code input

      $('.cc_stored_payments_selector_selected .cvv_input').keydown(function (event) {
        var keycode = event.which;

        if (!(event.shiftKey == false && (keycode == 46 || keycode == 8 || keycode == 37 || keycode == 39 || keycode >= 48 && keycode <= 57 || keycode >= 96 && keycode <= 105))) {
          event.preventDefault();
        }

        if ($('.cc_stored_payments_selector_selected .cvv_input').val().length >= 2 && $('.cc_stored_payments_selector_selected .cvv_input').val().length <= 4) {
          $('.cc_use_stored_payment').removeClass('disabled no_pointer_events');
        } else {
          $('.cc_use_stored_payment').addClass('disabled no_pointer_events');
        }

        if (keycode == 46 || keycode == 8) {
          if ($('.cc_stored_payments_selector_selected .cvv_input').val().length - 1 < 3) {
            $('.cc_use_stored_payment').addClass('disabled no_pointer_events');
          }
        }
      }); // Activate ".cc_use_stored_payment" on selec Purchase order label

      if ($label.hasClass('stored_payment_checkmark--po')) {
        $('.cc_use_stored_payment').removeClass('disabled no_pointer_events');
      }
    });
  }); // Scroll to page begin

  $('.cc_scroll-top').click(function () {
    $("html, body").stop().animate({
      scrollTop: 0
    }, 500, 'swing');
  });
});

CCRZ.setEqualDiscountContainerHeight = function () {
  setTimeout(function () {
    var discountHeight = 0;
    $('.discount-container').each(function (i, e) {
      var eH = $(this).height();

      if (discountHeight < eH) {
        discountHeight = eH;
      }
    });
    $('.discount-container').height(discountHeight).css('width', '100%');
  }, 300);
};

if (_variables.currentPage === "ccrz__ProductList") {
  CCRZ.pubSub.on('view:productItemView:refresh', function (view) {
    CCRZ.setEqualDiscountContainerHeight();
  });
}

$(window).on('load', function () {
  setTimeout(function () {
    CCRZ.widgetsCollapse();
    CCRZ.modalToggleScrollbar();
  }, 600);

  CCRZ.displayAddToCartConfirmation = function () {
    $('body').append('<div class="addToCartConfirmation_container"></div>');
    CCRZ.addToCartConfirmationView = new CCRZ.views.addToCartConfirmationView({
      el: '.addToCartConfirmation_container'
    });
    CCRZ.addToCartConfirmationView.render();
    $(document).mouseup(function (e) {
      var container = $(".addToCartConfirmation_container");

      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.find(".cc_navbar-fixed-bottom").remove();
      }
    });
  };

  if (_variables.currentPage === "ccrz__Cart") {
    CCRZ.rearangeCartItems();
  }
});
$(window).on('resize', function () {
  if (_variables.currentPage === "ccrz__HomePage") {
    if (CCRZ.OSFSpotlightProductsView) {
      CCRZ.OSFSpotlightProductsView.render();
    }

    if (CCRZ.OSFFeaturedProductsView) {
      CCRZ.OSFFeaturedProductsView.render();
    }
  } else if (_variables.currentPage === "ccrz__CheckoutNew") {
    if ($(window).width() >= 768 && $('.step-links').hasClass('hidden')) {
      $('.step-links').removeClass('hidden');
    } else if ($(window).width() < 768 && !$('.step-links').hasClass('hidden')) {
      $('.step-links').addClass('hidden');
    }
  } else if (_variables.currentPage === "ccrz__Cart") {
    CCRZ.rearangeCartItems();
  }
});

CCRZ.rearangeCartItems = function () {
  if ($(window).width() >= 768) {
    $(".shopping-cart-item-container").each(function () {
      var savingsContainer = $(this).find(".savings.cc_savings");
      var updateContainer = $(this).find(".item_qty_actions");
      $(this).find(".shopping-cart-item-price-tax-inc").removeClass("d-flex").append(savingsContainer);
      $(this).find(".cc_savings").removeClass('cart_item_savings_mobile cart_item_savings_mobile_ar'); //$(this).find(".updateCartButton").addClass('textbutton-blue').removeClass("cart_item_update_mobile").prependTo(updateContainer);

      $(this).find(".updateCartButton span:last").removeClass("hidden").append();
    });
  } else if ($(window).width() < 768) {
    $(".shopping-cart-item-container").each(function () {
      var savingsContainer = $(this).find(".shopping-cart-item-price");
      var updateContainer = $(this).find(".shopping-cart-item-price-tax-inc");
      $(this).find(".cc_savings").addClass('cart_item_savings_mobile').prependTo(savingsContainer);
      $(this).find(".updateCartButton").removeClass('textbutton-blue').addClass("cart_item_update_mobile").prependTo(updateContainer);
      $(this).find(".updateCartButton span:last").addClass("hidden");
      $(this).find(".shopping-cart-item-price-tax-inc").addClass("d-flex");

      if (CCRZ.pagevars.userLocale && CCRZ.pagevars.userLocale.startsWith("ar")) {
        $(".cart_item_savings_mobile").addClass(".cart_item_savings_mobile_ar");
      }
    });
  }
};

CCRZ.checkDescription = function (item) {
  item.each(function () {
    var desc = $(this).find(".description").text().trim();
    var descLength = desc.split(" ").length;
    var descWidth = $(this).find(".description").width();
    $("body").append('<span class="noWhiteSpace"> </span>');
    var newString = $(".noWhiteSpace");

    for (var i = 0; i < descLength; ++i) {
      var oldValue = $(newString).text();
      $(newString).append(desc.split(" ")[i]);

      if ($(newString).width() < 2 * descWidth - 10) {
        $(newString).append(" ");
      } else {
        i = descLength;
        $(newString).text(oldValue).append('&#x2026;');
        break;
      }
    }

    var newDesc = $(newString).text();
    $(this).find(".description").empty().text(newDesc);
    $(".noWhiteSpace").remove();
  });
};

CCRZ.buildQueryString = function (inputQueryString) {
  var queryString = inputQueryString; // if (CCRZ.pagevars.queryParams.pageKey) {
  //     queryString ? queryString += '&' : queryString += '?';
  //     queryString += 'pageKey=' + CCRZ.pagevars.queryParams.pageKey;
  // }

  if (CCRZ.pagevars.currentCartID) {
    queryString ? queryString += '&' : queryString += '?';
    queryString += 'cartId=' + CCRZ.pagevars.currentCartID;
  }

  if (CCRZ.pagevars.portalUserId) {
    queryString ? queryString += '&' : queryString += '?';
    queryString += 'portalUser=' + CCRZ.pagevars.portalUserId;
  }

  if (CCRZ.pagevars.storeName) {
    queryString ? queryString += '&' : queryString += '?';
    queryString += 'store=' + CCRZ.pagevars.storeName;
  }

  if (CCRZ.pagevars.effAccountId) {
    queryString ? queryString += '&' : queryString += '?';
    queryString += 'effectiveAccount=' + CCRZ.pagevars.effAccountId;
  }

  if (CCRZ.pagevars.priceGroupId) {
    queryString ? queryString += '&' : queryString += '?';
    queryString += 'grid=' + CCRZ.pagevars.priceGroupId;
  }

  if (CCRZ.pagevars.userLocale) {
    queryString ? queryString += '&' : queryString += '?';
    var locale = CCRZ.pagevars.userLocale.startsWith("ar") ? CCRZ.pagevars.arLocale : CCRZ.pagevars.enLocale;
    queryString += 'cclcl=' + locale;
  }

  if (CCRZ.pagevars.isGuest == true && CCRZ.pagevars.country && CCRZ.pagevars.country !== "FreeZone") {
    queryString ? queryString += '&' : queryString += '?';
    queryString += 'country=' + CCRZ.pagevars.country;
  }

  return queryString;
};

CCRZ.widgetsCollapse = function () {
  // Collapes all widgets on Homepage
  if (_variables.currentPage === "ccrz__HomePage") {
    $('.widgets-container').find('.minicart .panel-collapse.in').removeClass('in');
    $('.widgets-container').find('.wishlist-container .panel-collapse.in').removeClass('in');
    $('.widgets-container').find('.reorderContainer .panel-collapse.in').removeClass('in');
    $(".cc_mini_cart .cc_filter_icon .fa-caret-down").removeClass("rotateX180").addClass("rotateX0");
    $(".cc_wishlist_panel .cc_filter_icon .fa-caret-down").removeClass("rotateX180").addClass("rotateX0");
    $(".cc_re_order .cc_filter_icon .fa-caret-down").removeClass("rotateX180").addClass("rotateX0");
  }
};

CCRZ.prodContentEqualHeight = function (parentbox, contentBoxClass) {
  // Set equeal height for product Name and Description boxes
  setTimeout(function () {
    var prodNameHeight = 0;
    var prodDescriptionHeight = 0;
    parentbox.each(function (i, e) {
      var prodNameH = $(e).find(contentBoxClass + ' .cc_prod_name').height();
      var prodDescriptionH = $(e).find(contentBoxClass + ' .cc_description').height();

      if (prodNameH > prodNameHeight) {
        prodNameHeight = prodNameH;
      }

      if (prodDescriptionH > prodDescriptionHeight) {
        prodDescriptionHeight = prodDescriptionH;
      }
    });
    parentbox.find(contentBoxClass + ' .cc_prod_name').height(prodNameHeight);
    parentbox.find(contentBoxClass + ' .cc_description').height(prodDescriptionHeight);
  }, 100);
};

CCRZ.prodContentEqualWidth = function (parentbox, contentBoxClass) {
  // Set equeal height for product Name and Description boxes
  setTimeout(function () {
    var prodNameWidth = 0;
    var prodDescriptionWidth = 0;
    parentbox.each(function (i, e) {
      var prodNameW = $(e).find(contentBoxClass + ' .cc_prod_name').width();
      var prodDescriptionW = $(e).find(contentBoxClass + ' .cc_description').width();

      if (prodNameW > prodNameWidth) {
        prodNameWidth = prodNameW;
      }

      if (prodDescriptionW > prodDescriptionWidth) {
        prodDescriptionWidth = prodDescriptionW;
      }
    });
    parentbox.find(contentBoxClass + ' .cc_prod_name').width(prodNameWidth);
    parentbox.find(contentBoxClass + ' .cc_description').width(prodDescriptionWidth);
  }, 100);
};

CCRZ.staticPageLayoutExtend = function () {
  // Add extended class to Body Static page to extend it's style
  if (CCRZ.pagevars.queryParams.pageKey) {
    $('body.cc_main_body').addClass('is-static-page');
  }
};

CCRZ.modalToggleScrollbar = function () {
  $(".modal").on('show.bs.modal', function () {
    $("html").addClass("hideScrollBar_modal");
  }).on('hide.bs.modal', function () {
    $("html").removeClass("hideScrollBar_modal");
  });
};

CCRZ.reorderWidgetModalToggleScrollbar = function () {
  $(".cc_ordered_items_link").click(function () {
    $("html").addClass("hideScrollBar_modal");
  });

  if (CCRZ.views.OrderedItemsView) {
    CCRZ.views.OrderedItemsView.prototype.events = {
      'click .gp_prodLink': 'gotoProductDetail',
      'click .gp_btn_close': 'closeModal',
      'click .gp_btn_reorder': 'reorder',
      'click .cc_close': 'closeModal'
    };

    CCRZ.views.OrderedItemsView.prototype.closeModal = function () {
      $('#ordered_items').modal('hide');
      $("html").removeClass("hideScrollBar_modal");
    };
  }
};

CCRZ.formatPrice = function (amount, theCurrency) {
  if ('TRUE' === CCRZ.pagevars.pageConfig['pgl.curr']) {
    var theCurrency = theCurrency || CCRZ.userIsoCode;

    if (CCRZ.pagevars.userLocale && CCRZ.pagevars.userLocale.startsWith("ar")) {
      if (amount.toFixed(2) < 0) {
        return CCRZ.priceWithComma(Math.abs(amount).toFixed(2)) + '-  <span>' + CCRZ.pagevars.pageLabels["priceSymbol_" + theCurrency] + "</span>  ";
      } else {
        return CCRZ.priceWithComma(amount.toFixed(2)) + '  <span>' + CCRZ.pagevars.pageLabels["priceSymbol_" + theCurrency] + "</span>  ";
      }
    } else {
      return CCRZ.util.format(CCRZ.pagevars.pageLabels["price_" + theCurrency] || CCRZ.pagevars.pageLabels["price_Undefined"] || "$#,###.00", amount);
    }
  } else {
    var div = $("<div>" + amount + "</div>");
    div.currency({
      region: CCRZ.userIsoCode,
      decimals: CCRZ.pagevars.currencyData.decimalPoints,
      decimal: CCRZ.pagevars.currencyData.charDecimalPoint,
      thousands: CCRZ.pagevars.currencyData.charThousandsPoint,
      currencySymbol: CCRZ.pagevars.currencyData.currencySymbol,
      hidePrefix: !CCRZ.pagevars.currencyData.prefix,
      hidePostfix: CCRZ.pagevars.currencyData.prefix
    });
    return div.html();
  }
};

CCRZ.proofFileUpload = function () {
  // Add upload button
  $('#newWalletForm .cc_po_action_buttons').prepend('<a href="#" class="proofUpload cc_order_proof_upload textbutton-blue" data-toggle="modal" data-target="#orderProofUpload">' + CCRZ.pagevars.pageLabels.ProofUpload_AddProofOfPayment + '</a>');
  CCRZ.subsc = _.extend(CCRZ.subsc || {});
  var addedFiles = [];
  var fileCount = 0;
  var controller = "";
  var paramId = "";

  if (CCRZ.pagevars.currentPageName === "ccrz__CheckoutNew") {
    controller = "osf_ctrl_Checkout_BIE";
    paramId = CCRZ.pagevars.queryParams.cartID;
  } else if (CCRZ.pagevars.currentPageName === "ccrz__OrderConfirmation") {
    controller = "osf_ctrl_order_view_BIE";
    paramId = CCRZ.pagevars.queryParams.o;
  }

  CCRZ.subsc.orderRemoteActions = _.extend({
    className: controller,
    uploadProofOfPaymentAction: function uploadProofOfPaymentAction(callback) {
      var filesArray = [];
      $(addedFiles).each(function (i, e) {
        filesArray.push(_defineProperty({}, e.fileName, e.fileContent));
      });
      this.invokeCtx("uploadProofOfPayment", paramId, JSON.stringify(filesArray), function (resp) {
        callback(resp);
      }, {
        buffer: false,
        nmsp: false
      });
    }
  }, CCRZ.RemoteInvocation);
  $('#uploadProofOfPayment').off();
  $('#uploadProofOfPayment').on('click', function (e) {
    uploadFormReset();
  });
  $('#uploadProofOfPayment').on('change', function (e) {
    if (e.target.files.length) {
      // Render selected files list
      var filesInput = document.getElementById('uploadProofOfPayment').files;
      fileCount = filesInput.length;

      if (fileCount > 3) {
        $('.form-message__file-limit').show();
      } else {
        var filesAreValid = true;
        $(filesInput).each(function (index, file) {
          var reader = new FileReader();
          reader.readAsBinaryString(file);
          reader.onloadend = shipOff;

          function shipOff(event) {
            addedFiles.push({
              "fileName": file.name,
              "fileSize": file.size,
              "fileContent": window.btoa(event.target.result)
            });

            if (fileCount == index + 1) {
              $(addedFiles).each(function (i, e) {
                var fileSize = (e.fileSize / 1024 / 1000).toFixed(2);
                var fileIcon = '<span class="icon icon-ic_checkout"></span>';

                if (fileSize > 25) {
                  filesAreValid = false;
                  fileIcon = '<span class="icon icon-ic_cross"></span>';
                }

                if (!CCRZ.isArabicPage) {
                  $('.proof-files-list').append('<li>' + e.fileName + '&nbsp;&nbsp; ' + fileSize + ' ' + CCRZ.pagevars.pageLabels.ProofUpload_FileSizeUnitOfMeasure + ' &nbsp; &nbsp; ' + fileIcon + ' </li>');
                } else {
                  $('.proof-files-list').append('<li>' + '<span class="proof-file-item">' + fileSize + '</span>' + '<span class="proof-file-item">' + e.fileName + '</span>' + '<span class="proof-file-item">' + CCRZ.pagevars.pageLabels.ProofUpload_FileSizeUnitOfMeasure + '</span>' + fileIcon + '</li>');
                }
              });
              $('.files-info-panel').show();

              if (filesAreValid) {
                $('#uploadProofFiles-label').removeClass('disabled');
                $('#orderProofUpload .upload-step--2, #orderProofUpload .upload-step--3').addClass('step-is-active');
              } else {
                $('#orderProofUpload .form-message__size-limit').show();
              }
            }
          }
        });
      }
    } else {
      $('#uploadProofFiles-label').addClass('disabled');
      $('.proof-files-list').html('');
      $('.files-info-panel').hide();
    }
  });
  $('#uploadProofFiles-label').off().on('click', function () {
    if ($(this).hasClass('disabled')) {
      return;
    }

    $('body').append('<div id="overlay" class="modal-backdrop fade in"></div>');
    CCRZ.subsc.orderRemoteActions.uploadProofOfPaymentAction(function (resp) {
      if (resp.success) {
        $('#orderProofUpload .files-upload-form').hide();
        $('#orderProofUpload .form-message__upload-success').show();
      } else {
        $('#orderProofUpload .form-message__server-error').show();
      }

      $("#overlay").remove();
    });
  });
  $('#proof-files-remove').click(function (e) {
    e.preventDefault();
    uploadFormReset();
  });

  function uploadFormReset() {
    var form = $('#orderProofUpload');
    var formBody = form.find('.cc_modal_body'); // Clear vars

    addedFiles = [];
    fileCount = 0; // Hide Alerts/Warnings and show form wrapper

    formBody.find('.form-message__item.alert').hide();
    $('#orderProofUpload .files-upload-form').show(); // Cleare file select input

    $('#uploadProofOfPayment').val(""); //Reset form steps

    formBody.find('.upload-step--2, .upload-step--3').removeClass('step-is-active');
    formBody.find('#uploadProofFiles-label').addClass('disabled'); // Remove files from list

    formBody.find('.proof-files-list').html('');
    formBody.find('.files-info-panel').hide();
  }

  $('#orderProofUpload').on('hidden.bs.modal', function (e) {
    uploadFormReset();
  });
};

CCRZ.checkCardInputs = function () {
  var validForm = true;
  $("#frmTokenization input[type=text]").next().remove();
  $("#frmTokenization input[type=text]").each(function () {
    var input = $(this);

    if (input.prop('required') && input.val() === "") {
      validForm = false;
      input.parent().removeClass("has-success").addClass("has-error");
      input.parent().append('<em id="' + input.attr("id") + '-error" class="error help-block">' + CCRZ.pagevars.pageLabels.FieldRequired + '</em>');
    } else {
      input.parent().removeClass("has-error").addClass("has-success");
      input.next().remove();
    }
  });

  if (validForm) {
    return true;
  } else {
    return false;
  }
};

CCRZ.checkCardExpiration = function (input) {
  input.next().remove();

  if (input.val() === null) {
    input.parent().removeClass("has-success").addClass("has-error");
    input.parent().append('<em id="' + input.attr("id") + '-error" class="error help-block">' + CCRZ.pagevars.pageLabels.FieldRequired + '</em>');
  } else {
    input.parent().removeClass("has-error").addClass("has-success");
    input.next().remove();
  }
};

CCRZ.redirectToPDP = function (event) {
  var sku = $(event.currentTarget).data('sku');
  console.log("".concat(CCRZ.pageUrls.productDetails, "?cartID=").concat(CCRZ.pagevars.currentCartID, "&sku=").concat(sku).concat(getCSRQueryString()));
  window.location.href = "".concat(CCRZ.pageUrls.productDetails, "?cartID=").concat(CCRZ.pagevars.currentCartID, "&sku=").concat(sku).concat(getCSRQueryString());
};

CCRZ.isIOS = function () {
  return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform);
};

CCRZ.showPricingTiers = function (targetNode, button, index, nrOfTierItems) {
  $(".deskLayout").prepend('<div id="overlay" class="modal-backdrop fade in overlay' + index + '"></div>'); // Options for the observer (which mutations to observe)

  var config = {
    attributes: true,
    childList: true,
    subtree: true
  }; // Callback function to execute when mutations are observed

  var callback = function callback(mutationsList, observer) {
    for (var i = 0; i < mutationsList.length; i++) {
      var mutation = mutationsList[i];

      if (mutation.type === 'childList') {
        console.log('A child node has been added or removed.');

        if (index < nrOfTierItems - 1) {
          index++;
          CCRZ.showPricingTiers($(".tier_container_outer")[index], $('.shopping-cart-item-container .tier-toggle-col .tier-toggle')[index], index, nrOfTierItems);
          $(".overlay" + (index - 1)).remove();
        } else {
          $(".fade.in").remove();
        }

        observer.disconnect();
      } else if (mutation.type === 'attributes') {
        console.log('The ' + mutation.attributeName + ' attribute was modified.');
      }
    }
  }; // Create an observer instance linked to the callback function


  var observer = new MutationObserver(callback);
  $('.shopping-cart-item-container .tier-toggle-col .tier-toggle')[index].click(); // Start observing the target node for configured mutations

  observer.observe(targetNode, config);
};

CCRZ.productTitleEqualHeight = function (parentbox, contentBoxClass) {
  // Set equal height for product Name and Description boxes
  setTimeout(function () {
    var prodNameHeight = 0;
    var prodDescriptionHeight = 0;
    parentbox.each(function (i, e) {
      var prodNameH = $(e).find(contentBoxClass).height();
      var prodDescriptionH = $(e).find(contentBoxClass).height();

      if (prodNameH > prodNameHeight) {
        prodNameHeight = prodNameH;
      }

      if (prodDescriptionH > prodDescriptionHeight) {
        prodDescriptionHeight = prodDescriptionH;
      }
    });
    parentbox.height(prodNameHeight);
    parentbox.find(contentBoxClass).height(prodDescriptionHeight);
  }, 100);
};

CCRZ.priceHandling = function (amount) {
  var absVal = Math.abs(amount);

  if (CCRZ.pagevars.userLocale && CCRZ.pagevars.userLocale.startsWith("ar")) {
    if (amount.toFixed(2) < 0) {
      return CCRZ.priceWithComma(Math.abs(amount).toFixed(2)) + '-  <span>' + CCRZ.pagevars.pageLabels["priceSymbol_" + CCRZ.userIsoCode] + "</span>  ";
    } else {
      return CCRZ.priceWithComma(amount.toFixed(2)) + '  <span>' + CCRZ.pagevars.pageLabels["priceSymbol_" + CCRZ.userIsoCode] + "</span>  ";
    }
  } else {
    return new Handlebars.SafeString(CCRZ.formatPrice(absVal, false));
  }
};

CCRZ.pubSub.on('view:PromoDisp:rendered', function () {
  if (document.body.clientWidth >= 991) {
    // Reorganise Promotions for Desktop
    // Add breaks after every banner item.
    var $promoLinks = $('.widgets-container .cc_promo .cc_body .cc_promo_link');

    if ($promoLinks.length) {
      $promoLinks.each(function (i, e) {
        if (!$(e).next().is('br') && i < $promoLinks.length - 1) {
          $(e).after('<br/>');
        }
      });
    }
  } else {
    // Reorganise Promotions for mobile resolution 
    var widgets = $('.widgets-container');
    var promosPanel = widgets.find('.cc_promo'); // Remove banners with broken images from Side nav

    promosPanel.find('img').error(function () {
      $(this).parents('.side-banner').remove();
    }); // Move Promos to the end of widget list. According to design.

    widgets.find('.promotion-box-RightNav, .promotion-box-LeftNav').addClass('promotion-box--visible').appendTo('.cc_content_body');

    if (promosPanel.find('img.cc_promo_img').length > 1) {
      // Extend Promotions conponent wich doesn't contain promotions with links
      if (promosPanel.find('.side-banner-wrap').length == 0) {
        var promoPanelBody = promosPanel.find('.cc_body');
        promoPanelBody.find('br').remove();
        promoPanelBody.find('img.cc_promo_img').wrap("<div class='side-banner'></div>");
        promoPanelBody.wrapInner("<div class='side-banner-wrap'></div>");
      }

      var promosWrapper = promosPanel.find('.side-banner-wrap');
      setTimeout(function () {
        if (promosWrapper.hasClass('slick-initialized')) {
          promosWrapper.slick('unslick');
        }

        promosWrapper.addClass('osf-slider-wrap');
        promosWrapper.find('.side-banner').addClass('osf-slider-item'); // Transform Nav-Promo banners into slider on mobile devices

        promosWrapper.find('.side-banner br').remove();
        promosWrapper.slick({
          dots: false,
          infinite: false,
          speed: 300,
          slidesToShow: 1,
          rtl: CCRZ.isArabicPage,
          adaptiveHeight: true,
          prevArrow: '<button type="button" class="osf-slider-prev"></button>',
          nextArrow: '<button type="button" class="osf-slider-next"></button>'
        });
      }, 300);
    }
  }
});

CCRZ.priceWithComma = function (number) {
  var parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

},{"./_variables":9}],8:[function(require,module,exports){
"use strict";

$(function () {
  function parseArabic() {
    // PERSIAN (فارسی), ARABIC (عربي) , URDU (اُردُو)
    var yas = "٠١٢٣٤٥٦٧٨٩";
    yas = yas.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
      return d.charCodeAt(0) - 1632;
    }).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) {
      return d.charCodeAt(0) - 1776;
    });
  } // Restricts input for the set of matched elements to the given inputFilter function.


  (function ($) {
    $.fn.inputFilter = function (inputFilter) {
      return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    };
  })(jQuery);

  CCRZ.pubSub.on('view:osf_newCustomerView:refresh', function () {
    $("#phoneNumber").inputFilter(function (value) {
      value = value.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
        return d.charCodeAt(0) - 1632;
      }).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) {
        return d.charCodeAt(0) - 1776;
      });
      return /^\d*$/.test(value); // Allow digits only, using a RegExp
    }); //Mobile dropdown

    function formatStateResult(state) {
      if (!state.id) {
        return state.text;
      }

      var baseUrl = CCRZ.pagevars.themeBaseURL + "images/";
      var $state = $('<div><div class="select2-flag"><img src="' + baseUrl + state.element.title + '.png" class="img-flag" /></div><span class="select2-country">' + state.element.title + '</span><span class="select2-code">' + state.text + '</span></div>');
      return $state;
    }

    ;

    function formatStateSelection(state) {
      if (!state.id) {
        return state.text;
      }

      var baseUrl = CCRZ.pagevars.themeBaseURL + "images/";
      var $state = $('<div><div class="select2-flag"><img src="" class="img-flag" /></div><span class="select2-code"></span></div>'); // Use .text() instead of HTML string concatenation to avoid script injection issues

      $state.find("span").text(state.text);
      $state.find("img").attr("src", baseUrl + state.element.title + ".png");
      return $state;
    }

    ;
    $(".phoneRegistrationForm").select2({
      templateResult: formatStateResult,
      templateSelection: formatStateSelection
    });
    $('#useBillingAddress').click(function () {
      $('.cc_shipping_address_panel').toggle();
    });
    $('select.custom_dropdown').selectpicker('render');
    $('div.custom_dropdown').click(function () {
      $(this).removeClass('dropup');
    });
    $('.dropdown-toggle[data-id="billingCountry"]').click(function (e) {
      e.preventDefault();
      $('.dropdown-toggle[data-id="billingCountry"] .filter-option-inner-inner').text($('.dropdown-toggle[data-id="billingCountry"] .filter-option-inner-inner').text());
      $(".billingCountry  ul.dropdown-menu li").each(function (index) {
        var countryVal = $("#billingCountry").children()[index];
        $(this).attr('data-value', $(countryVal).val());
      });
      $('.billingCountry  ul.dropdown-menu li[data-value="default"]').removeClass('active selected');
      $('.billingCountry  ul.dropdown-menu li.selected').addClass('active');
      $('.billingCountry  ul.dropdown-menu li').click(function (e) {
        if (e.screenX && e.screenX != 0 && e.screenY && e.screenY != 0) {
          $(".billingCountry  ul.dropdown-menu li").removeClass('active selected');
          $(this).addClass('active selected');
        }
      });
      $('.dropdown-toggle[data-id="billingCountry"] .filter-option-inner-inner').text($(".billingCountry  ul.dropdown-menu li.active span").text());
    });
    setTimeout(function () {
      $('.dropdown-toggle[data-id="billingCountry"]').click().click();
      window.scrollTo(0, 0);
      $("html, body").animate({
        scrollTop: 0
      });
    }, 300);
    $('#newCustomerForm1 .select2-container').click(function () {
      if (CCRZ.pagevars.userLocale.startsWith("ar")) {
        if ($(window).width() < 768) {
          $('body > .select2-container').css({
            'left': $(window).width() - 220
          });
        } else {// $('body > .select2-container').css({
          //     'left': $('.cc_phone_label').offset().left - 220
          // });
        }
      }
    });
  });
  CCRZ.pubSub.on('view:newCustomerView:refresh', function () {
    CCRZ.models.osf_NewCustomer = CCRZ.CloudCrazeModel.extend({
      className: "osf_ctrl_Registration",
      init: function init(callback) {
        var m = this;
        this.invokeContainerLoadingCtx($('body'), 'getCountries', function (response) {
          console.log('response - ', response);

          if (response.data) {
            response.data.countries.push({
              name: "Other",
              phoneCode: "+",
              isVatApplied: false,
              mobileRegex: '^[0-9]{1,15}$'
            });
            m.set(m.parse(response.data));
            callback(response);
          } else {
            console.error(response);
          }
        }, {
          buffer: false,
          nmsp: false,
          timeout: 120000
        });
      },
      registerUser: function registerUser(newCustomerFormData, callback) {
        var model = this;
        model.invokeContainerLoadingCtx($('body'), 'register', newCustomerFormData, function (response) {
          if (response.success) {
            console.log('response - ', response);
            $('#newCustomerForm1, .formTypeContainer').addClass('hidden');
            $('body').animate({
              scrollTop: 0
            }, 0);

            if (response.data.status === 'P0') {
              CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('INFO', 'login_error_section', 'SiteRegistration_P0'));
            } else if (response.data.status === 'P1') {
              CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('INFO', 'login_error_section', 'SiteRegistration_P1'));
            } else if (response.data.status === 'P2') {
              CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('INFO', 'login_error_section', 'SiteRegistration_P2'));
            } else if (response.data.status === 'P3') {
              CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('INFO', 'login_error_section', 'SiteRegistration_P3'));
            } else if (response.data.status === 'P4') {
              CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('INFO', 'login_error_section', 'SiteRegistration_P4'));
            } else if (response.data.status === 'P5') {
              CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('INFO', 'login_error_section', 'SiteRegistration_P5'));
            } else if (response.data.status === 'P6') {
              CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('INFO', 'login_error_section', 'SiteRegistration_P6'));
            } else if (response.data.status === 'P00') {
              CCRZ.pubSub.trigger('pageMessage', CCRZ.createPageMessage('INFO', 'login_error_section', 'SiteRegistration_P00'));
            }
          } else {
            CCRZ.pageMessagesView.trigger(response);
            document.body.scrollTop = document.documentElement.scrollTop = 0;
          }
        }, {
          buffer: false,
          nmsp: false,
          timeout: 120000
        });
      },
      doLogin: function doLogin(username, password) {
        $("input[id$=hdnUsername]").val(username);
        $("input[id$=hdnPassword]").val(password);
        login();
      }
    });
    CCRZ.views.osf_newCustomerView = CCRZ.CloudCrazeView.extend({
      templateDesktop: CCRZ.util.template('osf_SiteRegister-NewCustomer-Desktop'),
      templatePhone: CCRZ.util.template('SiteRegister-NewCustomer-Mobile'),
      viewName: "osf_newCustomerView",
      events: {
        "change .country": "getCities",
        "click  .cc_submit": "submit",
        "click .login_error_section i": "goToLogin",
        "click .formSwitcher": "resetForm"
      },
      goToLogin: function goToLogin(event) {
        if (CCRZ.pagevars.currSiteURL) {
          window.location.href = CCRZ.pagevars.currSiteURL + "ccrz__CCSiteLogin" + CCRZ.buildQueryString('');
        } else {
          window.location.href = "/apex/ccrz__CCSiteLogin" + CCRZ.buildQueryString('');
        }
      },
      // getCities: function (event) {
      //     Functionality for cities dropdown preselection.
      //     var v = this;
      //     var selectedCountry = $(event.target).val();
      //     var stateList = CCRZ.newCustomerModel.attributes.countries.filter(function (item) {
      //         return item.name === selectedCountry;
      //     })
      //     if ($(event.target).val() !== 'default') {
      //         CCRZ.geoCodes.cities = stateList[0].cities;
      //         if ($(event.target).hasClass('billing')) {
      //             v.renderBillingCityList(selectedCountry);
      //         } else {
      //             v.renderShippingCityList(selectedCountry);
      //         };
      //     }
      // },
      submit: function submit(event) {
        var v = this;
        var form = $('#newCustomerForm1');

        if (form.valid()) {
          var formData = form.serializeObject();
          formData.phone = formData.phoneCode + formData.phoneNumber;
          formData.registrationType = $('.business-information').is(':visible') ? 'business' : 'personal';
          if (!$('.cc_shipping_address_panel').is(':visible')) v.useBillingAddress(formData);
          var userLocale = CCRZ.pagevars.userLocale;

          if (userLocale.startsWith('en')) {
            formData.locale = 'en_GB';
          } else {
            formData.locale = CCRZ.pagevars.userLocale;
          }

          console.log('formData - ', formData);
          $(form).find('input, select, button').attr('disabled', 'disabled');
          this.model.registerUser(formData, function (response) {});
        }
      },
      useBillingAddress: function useBillingAddress(form) {
        form.shippingAddress1 = jQuery("input[name='billingAddress1']").val();
        form.shippingAddress2 = jQuery("input[name='billingAddress2']").val();
        form.shippingCity = jQuery("input[name='billingCity']").val();
        form.shippingCountry = jQuery(".billingCountry ul.dropdown-menu li.active").data('value');
      },
      init: function init() {
        var v = this;
        v.model.init(function () {
          v.model.attributes.countries.forEach(function (item, i) {
            if (item.name === CCRZ.pagevars.country) {
              CCRZ.newCustomerModel.attributes.countries[i].selected = true;
            }
          });
          v.render();
          CCRZ.arabicLayout();
          $('.registrationPage.cc_main_container--ar').animate({
            'opacity': '1'
          }, 50);
          var IPcountry = CCRZ.pagevars.country;

          if (IPcountry) {
            $('#billingCountry option').each(function () {
              if ($(this).val() === IPcountry) {
                $(this).attr("selected", "selected");
                setTimeout(function () {
                  var currentCountry = CCRZ.pagevars.country.toLowerCase().replace(/\s+/g, '');
                  $('div.bootstrap-select.billingCountry .filter-option-inner-inner').text(CCRZ.pagevars.pageLabels[currentCountry]);
                }, 100);
              }
            });
            $('select.custom_dropdown').selectpicker('render');
            $('div.custom_dropdown').click(function () {
              $(this).removeClass('dropup');
            }); // Functionality for cities dropdown preselection.
            // var stateList = CCRZ.newCustomerModel.attributes.countries.filter(function (item) {
            //     return item.name === IPcountry;
            // })
            // CCRZ.geoCodes.cities = stateList[0].cities;
            // v.renderBillingCityList(IPcountry);
            // v.renderShippingCityList(IPcountry);
          }
        });
      },

      /*renderPhone: function() {
          var v = this;
          v.$el.html('');
          this.setElement($(CCRZ.uiProperties.newCustomerView.phone.selector));
          v.$el.html(v.templatePhone(v.model.toJSON()));
          v.renderStateLists();
          v.initValidationDesktop();
      },*/
      renderDesktop: function renderDesktop() {
        //CCRZ.arabicLayout();
        var v = this;
        v.$el.html('');
        v.setElement('.newCustomerSection');
        v.$el.html(v.templateDesktop(v.model.toJSON()));
        v.initValidationDesktop();
      },
      // Functionality for cities dropdown preselection.
      // renderBillingCityList: function () {
      //     var v = this;
      //     v.renderBillingCityView = new CCRZ.views.billingCityView({
      //         model: CCRZ.geoCodes.cities
      //     });
      //     v.renderBillingCityView.render();
      //     //apply pre-selection
      //     $('.custom_dropdown').selectpicker('render');
      // },
      // renderShippingCityList: function () {
      //     var v = this;
      //     v.renderShippingCityView = new CCRZ.views.shippingCityView({
      //         model: CCRZ.geoCodes.cities
      //     });
      //     v.renderShippingCityView.render();
      //     //apply pre-selection
      //     $('.custom_dropdown').selectpicker('render');
      // },
      resetForm: function resetForm() {
        var v = this;
        var eventEl = $(event.target).data('name');

        if (eventEl === 'personal' && $('.business-information').is(':visible')) {
          $('.business-information').css('display', 'none');
          $('#newCustomerForm1')[0].reset();
          v.validator.resetForm();
        } else if (eventEl === 'business' && !$('.business-information').is(':visible')) {
          $('.business-information').css('display', 'block');
          $('#newCustomerForm1')[0].reset();
          v.validator.resetForm();
        }

        $('.has-success').removeClass('has-success');
        $('.cc_shipping_address_panel').css('display', 'none');
      },
      initValidationDesktop: function initValidationDesktop() {
        var v = this;
        jQuery.validator.addMethod("numberNotStartWithZero", function (value, element) {
          return this.optional(element) || /^[1-9][0-9]+$/i.test(value);
        }, "Please enter a valid number. (Do not start with zero)");
        jQuery.validator.addMethod("phoneNumberLength", function (value, element) {
          var locale = CCRZ.pagevars.country;
          var regex;
          CCRZ.newCustomerModel.attributes.countries.forEach(function (item) {
            if (item.name === locale) {
              regex = item.mobileRegex;
            } else if (item.name === "Other") {
              regex = item.mobileRegex;
            }
          });
          return this.optional(element) || new RegExp(regex).test(value);
        });
        jQuery.validator.addMethod("optionNotSelected", function (value, element) {
          return this.optional(element) || value !== 'default';
        });
        jQuery.validator.addMethod("checkEmailFormat", function (value, element) {
          return this.optional(element) || /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value);
        });
        v.validator = jQuery('#newCustomerForm1').validate({
          invalidHandler: function invalidHandler(event, validator) {
            if (!CCRZ.disableAdaptive) {
              CCRZ.handleValidationErrors(event, validator, 'error_messages_section', false);
            }
          },
          rules: {
            firstName: {
              required: true
            },
            lastName: {
              required: true
            },
            phoneNumber: {
              required: true,
              numberNotStartWithZero: true,
              phoneNumberLength: true
            },
            companyName: {
              required: true
            },
            vatNumber: {
              required: function required() {
                var IPcountry = CCRZ.pagevars.country;
                var isVatApplied = CCRZ.newCustomerModel.attributes.countries.some(function (item) {
                  if (item.name === IPcountry) {
                    return item.isVatApplied;
                  }
                });
                return isVatApplied;
              }
            },
            email: {
              required: true,
              checkEmailFormat: true
            },
            confirmEmail: {
              required: true,
              equalTo: '#email'
            },
            billingAddress1: {
              required: true
            },
            billingCity: {
              required: true
            },
            billingCountry: {
              required: true,
              optionNotSelected: true
            },
            shippingAddress1: {
              required: true
            },
            shippingCity: {
              required: true
            },
            shippingCountry: {
              required: true,
              optionNotSelected: true
            }
          },
          messages: {
            firstName: {
              required: CCRZ.pagevars.pageLabels['SR_FNameRequired']
            },
            lastName: {
              required: CCRZ.pagevars.pageLabels['SR_LNameRequired']
            },
            phoneNumber: {
              required: CCRZ.pagevars.pageLabels['SR_PhoneRequired'],
              phoneNumberLength: CCRZ.pagevars.pageLabels['SR_PhoneNumberNotCorrect'],
              numberNotStartWithZero: CCRZ.pagevars.pageLabels['SR_PhoneNumberDontStartZero']
            },
            companyName: {
              required: CCRZ.pagevars.pageLabels['SR_CompanyNameRequired']
            },
            vatNumber: {
              required: CCRZ.pagevars.pageLabels['SR_VATnumber']
            },
            email: {
              required: CCRZ.pagevars.pageLabels['SR_UserNameRequired'],
              checkEmailFormat: CCRZ.pagevars.pageLabels['SR_UserNameInvalidFormat']
            },
            confirmEmail: {
              required: CCRZ.pagevars.pageLabels['SR_UserNameRequired'],
              email: CCRZ.pagevars.pageLabels['SR_UserNameInvalidFormat']
            },
            billingAddress1: {
              required: CCRZ.pagevars.pageLabels['SR_BillAddrRequired']
            },
            billingCity: {
              required: CCRZ.pagevars.pageLabels['SR_BillCityRequired']
            },
            billingCountry: {
              required: CCRZ.pagevars.pageLabels['SR_BillCountryRequired'],
              optionNotSelected: CCRZ.pagevars.pageLabels['SR_BillCountryRequired']
            },
            shippingAddress1: {
              required: CCRZ.pagevars.pageLabels['SR_ShipAddrRequired']
            },
            shippingCity: {
              required: CCRZ.pagevars.pageLabels['SR_ShipCityRequired']
            },
            shippingCountry: {
              required: CCRZ.pagevars.pageLabels['SR_ShipCountryRequired'],
              optionNotSelected: CCRZ.pagevars.pageLabels['SR_ShipCountryRequired']
            }
          },
          errorElement: "em",
          errorPlacement: function errorPlacement(error, element) {
            // Needed for bootstrap 3 (per validator documentation)
            if (CCRZ.disableAdaptive) {
              error.addClass("help-block");

              if (element.prop("type") === "checkbox") {
                error.insertAfter(element.parent("label"));
              } else if (element.prop('tagName') === "SELECT") {
                error.insertAfter(element.next());
              } else {
                error.insertAfter(element);
              }
            }
          },
          highlight: function highlight(element, errorClass, validClass) {
            if (CCRZ.disableAdaptive) {
              $(element).parents(".col-sm-4").addClass("has-error").removeClass("has-success");
            }
          },
          unhighlight: function unhighlight(element, errorClass, validClass) {
            if (CCRZ.disableAdaptive) {
              $(element).parents(".col-sm-4").addClass("has-success").removeClass("has-error");
            }
          }
        });
      }
    });
    CCRZ.newCustomerModel = new CCRZ.models.osf_NewCustomer();
    CCRZ.newCustomerView = new CCRZ.views.osf_newCustomerView({
      model: CCRZ.newCustomerModel
    }); // Functionality for cities dropdown preselection.
    // CCRZ.views.billingCityView = CCRZ.CloudCrazeView.extend({
    //     templateDesktop: CCRZ.util.template('osf_RegistrationBillingCity'),
    //     templatePhone: CCRZ.util.template('osf_RegistrationBillingCity'),
    //     viewName: "billingCityView",
    //     className: 'billingCitySection',
    //     doRender(tmpl) {
    //         $(`.${this.className}`).html(tmpl(this.model));
    //     },
    //     renderDesktop() {
    //         this.doRender(this.templateDesktop);
    //     },
    //     renderPhone() {
    //         this.renderDesktop();
    //     },
    // });
    // CCRZ.views.shippingCityView = CCRZ.CloudCrazeView.extend({
    //     templateDesktop: CCRZ.util.template('osf_RegistrationShippingCity'),
    //     templatePhone: CCRZ.util.template('osf_RegistrationShippingCity'),
    //     viewName: "shippingCityView",
    //     className: 'shippingCitySection',
    //     doRender(tmpl) {
    //         $(`.${this.className}`).html(tmpl(this.model));
    //     },
    //     renderDesktop() {
    //         this.doRender(this.templateDesktop);
    //     },
    //     renderPhone() {
    //         this.renderDesktop();
    //     },
    // });
  });
});

},{}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.currentLocale = exports.currentPage = void 0;
var currentPage = CCRZ.pagevars.currentPageName;
exports.currentPage = currentPage;
var currentLocale = CCRZ.pagevars.userLocale;
exports.currentLocale = currentLocale;

},{}],10:[function(require,module,exports){
"use strict";

require("../js/_variables");

require("../js/_PDP");

require("../js/_PLP");

require("../js/_main");

require("../js/_helper");

require("../js/_registration");

require("../js/_account-passwordChange");

require("../js/_forgotPass");

require("../js/_checkout");

},{"../js/_PDP":1,"../js/_PLP":2,"../js/_account-passwordChange":3,"../js/_checkout":4,"../js/_forgotPass":5,"../js/_helper":6,"../js/_main":7,"../js/_registration":8,"../js/_variables":9}]},{},[10]);
